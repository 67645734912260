import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, mapTo, shareReplay, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Token } from '../../shared/models/token';
import { environment } from '../../../environments/environment';
import { User } from '../../shared/models/user';
import { Login } from 'src/app/shared/models/login';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  private readonly SESSION_USER = 'SESSION_USER';
  // private loggedUser:User = new User();

  constructor(private http: HttpClient) {
  }

  login(user, pass) {
    let data={
      emailOrUsername : user,
      password : pass
    }
    return this.http.post(`${environment.apiUrl}/auth/login`, data).pipe(
      map((res: any) => {
        return res;
      })
    );
  }
  dataUser(user,token) {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', "Bearer "+ token);
    return this.http.get(`${environment.apiUrl}/user/me/${user}`, { headers: headers}).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  setToken() {
    const body = new HttpParams()
      .set('client_id', environment.client_id)
      .set('client_secret', environment.client_secret);
    return this.http.post(`${environment.apiUrl}/v1.0/oauth/client_credential/accesstoken?grant_type=client_credentials`,
      body.toString(),
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
      }).pipe(tap(
        (res: any) => {
          return res;
        })
      );
  }

  logout() {

  }
  recuperarContraseña(user) {

    return this.http.post(`${environment.apiUrl}/usuarios/password?username=${user} `, {}).pipe(tap(
      (res: any) => {
        return res;
      })
    );
  }

  isLoggedIn(): boolean {
    if (localStorage.getItem('userIdPae')) {
      return true;
    } else {
      return false;

    }
  }


  getCurrentUser() {
    return localStorage.getItem(this.SESSION_USER);
  }

  refreshToken() {
    const refreshToken = new Token();
    refreshToken.refresh_token = this.getRefreshToken();

    return this.http.post<Token>(`${environment.apiUrl}/refresh/token`, refreshToken).pipe(
      tap((token: Token) => {
        this.storeJwtToken(token.token);
      }));
  }

  getToken() {
    return localStorage.getItem(this.JWT_TOKEN);
  }

  // private doLoginUser(username:string, token:Token) {
  //   this.loggedUser.username = username;
  //   this.loggedUser.name = token.data.name;
  //   this.storeTokens(token);
  // }

  private getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  private storeJwtToken(token: string) {
    localStorage.setItem(this.JWT_TOKEN, token);
  }

  // private doLogoutUser() {
  //   this.loggedUser = new User();
  //   this.removeTokens();
  // }

  private storeTokens(token: Token) {
    localStorage.setItem(this.JWT_TOKEN, token.token);
    localStorage.setItem(this.REFRESH_TOKEN, token.refresh_token);
    localStorage.setItem(this.SESSION_USER, token.data.name);
  }

  public removeTokens() {
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
    localStorage.removeItem(this.SESSION_USER);
  }
}
