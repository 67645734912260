import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { ReposicionComponent } from './components/reposicion/reposicion.component';
import { BatchcamionComponent } from './components/batchcamion/batchcamion.component';
import { HistorialComponent } from './components/historial/historial.component';
import { ParametrosComponent } from './components/parametros/parametros.component';
import { VerComponent } from './components/parametros/ver/ver.component';
import { EditarComponent } from './components/parametros/editar/editar.component';
import { ResultadosComponent } from './components/resultados/resultados.component';
import { ResultadosReposicionVerComponent } from './components/resultados/resultados-reposicion-ver/resultados-reposicion-ver.component';
import { ResultadosListComponent } from './components/resultados/resultados-list/resultados-list.component';
import { ResultadosHistorialVerComponent } from './components/resultados/resultados-historial-ver/resultados-historial-ver.component';
import { ResultadosBatchcamionVerComponent } from './components/resultados/resultados-batchcamion-ver/resultados-batchcamion-ver.component';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { VerMasComponent } from './components/ver-mas/ver-mas.component';
import { UsuariosComponent } from './components/usuario/usuarios/usuarios.component';
import { AdminGuard } from 'src/app/core/guards/admin.guard';


const routes:Routes = [
  {path:'verMas', component:VerMasComponent,canActivate: [AuthGuard]  },
  {path:'reposicion', component:ReposicionComponent,canActivate: [AuthGuard]  },
  {path:'usuarios', component:UsuariosComponent,canActivate: [AuthGuard,AdminGuard]  },
  {path:'batchcamion',component:BatchcamionComponent,canActivate: [AuthGuard]  },
  {path:'historial',component:HistorialComponent,canActivate: [AuthGuard]  },
  {path:'parametros', component:ParametrosComponent ,canActivate: [AuthGuard]  },
  {path:'parametros/ver', component:VerComponent,canActivate: [AuthGuard]  },
  {path:'parametros/editar', component:EditarComponent,canActivate: [AuthGuard]  },
  {path:'resultados', component:ResultadosComponent,canActivate: [AuthGuard]  },
  {path:'resultados/reposicion', component:ResultadosListComponent,canActivate: [AuthGuard]  },
  {path:'resultados/reposicion/ver', component:ResultadosReposicionVerComponent,canActivate: [AuthGuard]  },
  {path:'resultados/batchcamion', component:ResultadosListComponent,canActivate: [AuthGuard]  },
  {path:'resultados/batchcamion/ver', component:ResultadosBatchcamionVerComponent,canActivate: [AuthGuard]  },
  {path:'resultados/historial', component:ResultadosListComponent,canActivate: [AuthGuard]  },
  {path:'resultados/historial/ver', component:ResultadosHistorialVerComponent,canActivate: [AuthGuard]  },


];

@NgModule({
  imports:[RouterModule.forChild(routes)],
  exports:[RouterModule]
})
export class ShellRoutingModule {
}
