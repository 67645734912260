import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import {ShellRoutingModule} from './shell-routing.module';
import {ShellComponent} from './pages/shell/shell.component';
import {MainComponent} from './pages/main/main.component';
import {FooterComponent} from './pages/footer/footer.component';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatToolbarModule} from '@angular/material/toolbar';
import {GoBackDirective} from '../../shared/directives/go-back.directive';
import {CloseMenuDirective} from '../../shared/directives/close-menu.directive';
import { SharedModule } from 'primeng/api';
import {MenubarModule} from 'primeng/menubar';
import { PrimengModule } from 'src/app/shared/primeng/primeng.module';
import {PanelMenuModule} from 'primeng/panelmenu';
import { ReposicionComponent } from './components/reposicion/reposicion.component';
import { BatchcamionComponent } from './components/batchcamion/batchcamion.component';
import { HistorialComponent } from './components/historial/historial.component';
import { ParametrosComponent } from './components/parametros/parametros.component';
import { VerComponent } from './components/parametros/ver/ver.component';
import { EditarComponent } from './components/parametros/editar/editar.component';
import { ResultadosComponent } from './components/resultados/resultados.component';
import { ResultadosReposicionVerComponent } from './components/resultados/resultados-reposicion-ver/resultados-reposicion-ver.component';
import { ResultadosListComponent } from './components/resultados/resultados-list/resultados-list.component';
import { ResultadosHistorialVerComponent } from './components/resultados/resultados-historial-ver/resultados-historial-ver.component';
import { ResultadosBatchcamionVerComponent } from './components/resultados/resultados-batchcamion-ver/resultados-batchcamion-ver.component';
import { VerMasComponent } from './components/ver-mas/ver-mas.component';
import { UsuariosComponent } from './components/usuario/usuarios/usuarios.component';


@NgModule({
  declarations:[
    ShellComponent, MainComponent, FooterComponent,
    GoBackDirective, CloseMenuDirective, ReposicionComponent, BatchcamionComponent, HistorialComponent, ParametrosComponent, VerComponent, EditarComponent, ResultadosComponent, ResultadosReposicionVerComponent, ResultadosListComponent, ResultadosHistorialVerComponent, ResultadosBatchcamionVerComponent, VerMasComponent, UsuariosComponent,
  ],
  imports:[
    PanelMenuModule,
    PrimengModule,
    MenubarModule,
    CommonModule, ShellRoutingModule,SharedModule,
    MatCardModule, MatSidenavModule, MatToolbarModule, MatListModule, MatIconModule, MatButtonModule, MatSnackBarModule, MatExpansionModule,
  ],
  exports:[ShellComponent, ShellRoutingModule, CloseMenuDirective, GoBackDirective],
  schemas:[ CUSTOM_ELEMENTS_SCHEMA ],

})
export class ShellModule {
}
