<p-toast position="top-right" key="msg"></p-toast>

<div class="container">
    <div class="p-fluid grid formgrid row">

        <div class="field col-12 md:col-12">
            <h2>Resultados - Batch / Camión<br /></h2>
        </div>

        <div class="field col-12 md:col-12 ">
            <p-divider></p-divider>
        </div>
        <div class="ml-5">
            <p-dropdown [options]="Archivoes" [(ngModel)]="selectedArchivo" optionLabel="name" [filter]="true"
                filterBy="name" [showClear]="true" placeholder="Seleciona un Archivo">
                <ng-template pTemplate="selectedItem">
                    <div class="country-item country-item-value" *ngIf="selectedArchivo">
                        <div>{{selectedArchivo.name}}</div>
                    </div>
                </ng-template>
                <ng-template let-country pTemplate="item">
                    <div class="country-item">
                        <div>{{country.name}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
        </div>
        <div class="field col-lg-12 col-xl-12 " *ngIf="progressBarValue== 100 ">

            <p-card>
                <div class="scroll">
                    <p-table [value]="list" sortMode="multiple">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class='text-center' pSortableColumn="ut">
                                    <p-sortIcon field="name"></p-sortIcon>UT + KIT
                                </th>
                                <th class='text-center' pSortableColumn="periodç">
                                    <p-sortIcon field="name"></p-sortIcon>Período
                                </th>
                                <th class='text-center' pSortableColumn="promedio">
                                    <p-sortIcon field="name"></p-sortIcon>Promedio Bacheo (Días)
                                </th>
                                <th class='text-center' pSortableColumn="dispersion">
                                    <p-sortIcon field="name"></p-sortIcon>Dispersión Bacheo (Días)
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-item>
                            <tr>
                                <td class='text-center'>{{item.ut}}</td>
                                <td class='text-center'>{{item.periodo}}</td>
                                <td class='text-center'>{{item.promedio}}</td>
                                <td class='text-center'>{{item.dispersion}}</td>

                            </tr>

                        </ng-template>
                    </p-table>
                </div>
            </p-card>
            <!--<p-paginator [rows]="rows" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"
                (onPageChange)="paginate($event)"></p-paginator>-->
        </div>
        <div class="field col-lg-12 col-xl-12 " *ngIf="progressBarValue!== 100">
            <p-card>
                <span class="text-center">{{progressBarText}}</span>
                <p-progressBar [value]="progressBarValue "></p-progressBar>
            </p-card>
        </div>
    </div>