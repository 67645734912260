import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {map} from "rxjs/operators";


@Injectable({
    providedIn:'root'
})
export class ApiServerService {

    constructor(private http:HttpClient) {
    }

    optimizerPost(data:FormData) {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', "Bearer "+ localStorage.getItem("token"));
        return this.http.post(`${environment.apiUrl}/optimizer/save`,data,{ headers: headers,responseType: 'text' }).pipe(
            map((res:string) => {
                return res;
            })
        );
    }

    historyPost(data:FormData) {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', "Bearer "+ localStorage.getItem("token"));
        return this.http.post(`${environment.apiUrl}/historial/save`,data,{ headers: headers,responseType: 'text' }).pipe(
            map((res:string) => {
                return res;
            })
        );
    }

    getAll(page,type,size,name) {
        let idUser=localStorage.getItem("userIdPae");
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', "Bearer "+ localStorage.getItem("token"));
        return this.http.get(`${environment.apiUrl}/optimizer/getAll/${idUser}?page=${page}&size=${size}&type=${type}&entradaName=${name}&sort=id,desc`).pipe(
            map((res:any) => {
                return res;
            })
        );
    } 
    getAllHistory(page,size,name) {
        let idUser=localStorage.getItem("userIdPae");
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', "Bearer "+ localStorage.getItem("token"));
        return this.http.get(`${environment.apiUrl}/historial/getAll/${idUser}?page=${page}&entradaName=${name}&size=${size}&sort=id,desc`).pipe(
            map((res:any) => {
                return res;
            })
        );
    }

    findOptimizerById(id) {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', "Bearer "+ localStorage.getItem("token"));
        return this.http.get(`${environment.apiUrl}/optimizer/findOptimizerById/`+ id,{ headers: headers}).pipe(
            map((res:any) => {
                return res;
            })
        );
    }

    findResultName(id) {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', "Bearer "+ localStorage.getItem("token"));
        return this.http.get(`${environment.apiUrl}/file/findResultName/`+ id,{ headers: headers}).pipe(
            map((res:any) => {
                return res;
            })
        );
    }

    findSourceName(id) {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', "Bearer "+ localStorage.getItem("token"));
        return this.http.get(`${environment.apiUrl}/file/findSourceName/`+ id,{ headers: headers,responseType: 'text'}).pipe(
            map((res:any) => {
                return res;
            })
        );
    }

    newParameter(data:FormData) {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', "Bearer "+ localStorage.getItem("token"));
        return this.http.post(`${environment.apiUrl}/parameters/save`,data,{ headers: headers,responseType: 'text' }).pipe(
            map((res:string) => {
                return res;
            })
        );
    }
    parameterFindAll(page) {
        let idUser=localStorage.getItem("userIdPae");
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', "Bearer "+ localStorage.getItem("token"));
        return this.http.get(`${environment.apiUrl}/parameters/getAll/${idUser}?page=${page}&size=10&sort=id,desc`).pipe(
            map((res:any) => {
                return res;
            })
        );
    }
    parameterAll() {
        let idUser=localStorage.getItem("userIdPae");
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', "Bearer "+ localStorage.getItem("token"));
        return this.http.get(`${environment.apiUrl}/parameters/getAll/${idUser}`).pipe(
            map((res:any) => {
                return res;
            })
        );
    }

    parameterEdit(data:FormData) {
        let idUser=localStorage.getItem("userIdPae");
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', "Bearer "+ localStorage.getItem("token"));
        return this.http.put(`${environment.apiUrl}/parameters/updateActive/`+idUser,data,{ responseType: 'text' }).pipe(
            map((res:any) => {
                return res;
            })
        );
    }

    getFile(url){
        return this.http.get(url,{ responseType: 'blob' as "json"}).pipe(
            map((res:any) => {
                return res;
            })
        );
    }

    userFindAll() {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', "Bearer "+ localStorage.getItem("token"));
        return this.http.get(`${environment.apiUrl}/user/getAll`,{ headers:headers }).pipe(
            map((res:any) => {
                return res;
            })
        );
    }
    newUser(data:any) {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', "Bearer "+ localStorage.getItem("token"));
        return this.http.post(`${environment.apiUrl}/auth/register`,data,{ headers: headers,responseType: 'text' }).pipe(
            map((res:string) => {
                return res;
            })
        );
    }
    
}
