import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/core/authentication/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  progressBarValue = 100;
  keywords: any = [];
  formDatos = this.form.group({
    user: ['admin', Validators.required],
    pass: ['admin', Validators.required],
  });

  constructor(private router: Router, private form: FormBuilder, private messageService: MessageService, private authServices: AuthService) {
    var home = document.getElementById("home");
    home.style.left = "0px"
    document.getElementById("constr").style.display = "none";
    localStorage.clear();
  }

  ngOnInit(): void {
    document.getElementById("home").style.width = "100%"

  }

  submit() {
    if (this.formDatos.invalid) {
      this.messageService.add({ key: 'msg', severity: 'warn', summary: 'error', detail: "Falran completar campos" });
    } else {
      let user = this.formDatos.get('user').value;
      let pass = this.formDatos.get('pass').value;
      this.authServices.login(user, pass).subscribe(auth => {
        localStorage.setItem('token', auth.accessToken)
        this.authServices.dataUser("", auth.accessToken).subscribe(user => {
          localStorage.setItem('userIdPae', user.id)
          localStorage.setItem('userName', user.username)
          localStorage.setItem('userRole', user.roles[0].nombre)
          this.messageService.add({ key: 'msg', severity: 'success', summary: 'Hecho!', detail: 'Inicio de sesión exitoso! ' });
          setTimeout(() => {
            this.router.navigate(["/home"]);
          }, 500)
        })

      },
        authError => {
          this.messageService.add({ key: 'msg', severity: 'error', summary: 'Error', detail: 'Usuario o contraseña incorrectos' });
        })

    }
  }
}


