import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-ver',
  templateUrl: './ver.component.html',
  styleUrls: ['./ver.component.scss']
})
export class VerComponent implements OnInit {
  list: any[] = []
  totalRecords: number;
  rows = 10;
  page = 0;
  columnasSelected: any;
  palabra = "";
  columnas = [
    { name: 'Producto', value: 1 },
    { name: 'Presentación(LTS)', value: 2 },
    { name: 'Formato', value: 3 }
  ];
  progressBarValue = 0;
  progressBarText = "Cargando datos...";

  constructor(private messageService: MessageService, private router: Router) {
    this.list.push({ nombre: "parametro 1" });
    this.list.push({ nombre: "parametro 2" });
    this.list.push({ nombre: "parametro 3" });
    this.list.push({ nombre: "parametro 4" });
    this.list.push({ nombre: "parametro 5" });
    this.list.push({ nombre: "parametro 6" });
    this.list.push({ nombre: "parametro 7" });
    this.list.push({ nombre: "parametro 8" });
    this.list.push({ nombre: "parametro 9" });
    this.list.push({ nombre: "parametro 10" });
    this.list.push({ nombre: "parametro 11" });
    this.list.push({ nombre: "parametro 12" });
    this.list.push({ nombre: "parametro 13" });
    this.list.push({ nombre: "parametro 14" });
    this.list.push({ nombre: "parametro 15" });
    this.list.push({ nombre: "parametro 16" });
    this.list.push({ nombre: "parametro 17" });
    this.list.push({ nombre: "parametro 18" });
    this.totalRecords = this.list.length;

    let interval = setInterval(() => {
      this.progressBarValue = this.progressBarValue + Math.floor(Math.random() * 10) + 1;
      if (this.progressBarValue >= 100) {
        this.progressBarValue = 100;
        clearInterval(interval);
      }
    }, 100);
  }

  ngOnInit(): void {
  }

  paginate(event) {
    this.progressBarText = "Cambiando pagina...";
    this.progressBarValue = 0;
    let interval = setInterval(() => {
      this.progressBarValue = this.progressBarValue + Math.floor(Math.random() * 10) + 1;
      if (this.progressBarValue >= 100) {
        this.progressBarValue = 100;
        clearInterval(interval);
      }
    }, 100);
  }

  filtrar() {
    this.progressBarText = "Filtrando...";
    this.progressBarValue = 0;
    let interval = setInterval(() => {
      this.progressBarValue = this.progressBarValue + Math.floor(Math.random() * 10) + 1;
      if (this.progressBarValue >= 100) {
        this.progressBarValue = 100;
        clearInterval(interval);
      }
    }, 100);

  }

  limpiar() {
    this.progressBarText = "Limpiando...";
    this.progressBarValue = 0;
    let interval = setInterval(() => {
      this.progressBarValue = this.progressBarValue + Math.floor(Math.random() * 10) + 1;
      if (this.progressBarValue >= 100) {
        this.progressBarValue = 100;
        clearInterval(interval);
      }
    }, 100);
    this.columnasSelected = null;
    this.palabra = null;
  }
}
