import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {CalendarModule} from 'primeng/calendar';
import {ListboxModule} from 'primeng/listbox';
import {ButtonModule} from 'primeng/button';
import {FieldsetModule} from 'primeng/fieldset';

@NgModule({
  declarations: [
    
  ],
  imports: [
    FieldsetModule,
    ButtonModule,
    ListboxModule,
    CalendarModule,
    FormsModule,
    CommonModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
    ],
  providers: [],
  exports: [
    ],
  entryComponents: [],

})
export class SharedModule {
}
