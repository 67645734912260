<p-toast position="top-right" key="msg"></p-toast>

<div class="container">
    <div class="p-fluid grid formgrid row">

        <div class="field col-12 md:col-12">
            <h2>Parametros - Ver - Productos<br /></h2>
        </div>
        <div class="field  col-lg-12 col-xl-4 ">
            <p-card class="card-filtros">
                <div class="ui large form">
                    <div class="p-fluid grid formgrid">
                        <div class="field col-12 md:col-12 ">
                            <h3>Filtros</h3>
                            <p-divider></p-divider>
                        </div>
                        <div class="field col-12 md:col-12 ">
                            <label for="palabra">Filtrar por palabra</label>
                            <div class="p-inputgroup">
                                <input type="text" [(ngModel)]="palabra" pInputText placeholder="Ingresar palabra...">
                                <button type="button" (click)="filtrar()" pButton pRipple icon="pi pi-search"
                                    styleClass="p-button-warn"></button>
                            </div>

                        </div>
                        <div class="field col-12 md:col-12 ">
                            <p-divider></p-divider>
                        </div>
                        <div class="field col-12 md:col-12 ">
                            <label for="palabra">Filtrar por columna</label>
                            <div class="flex">
                                <p-selectButton class="selectbuttomMod" [options]="columnas"
                                    [(ngModel)]="columnasSelected" multiple="multiple" optionLabel="name"
                                    optionValue="value"></p-selectButton>
                            </div>

                        </div>
                        <div class="field col-12 md:col-12 ">
                            <p-divider></p-divider>
                        </div>


                        <div class="card-filtros-buttom">
                            <div class="field col-12 md:col-12 ">
                                <button pButton pRipple type="button" class="float-right btn" icon="pi pi-check "
                                    (click)="filtrar()" label="Filtrar"></button>
                            </div>
                            <div class="field col-12 md:col-12 ">
                                <button pButton pRipple type="button" class="float-right btn" icon="pi pi-check "
                                    (click)="limpiar()" label="Limpiar"></button>
                            </div>
                        </div>

                    </div>

                </div>
            </p-card>
        </div>
        <div class="field  col-lg-12 col-xl-8 " *ngIf="progressBarValue == 100">
            <p-card>
                <div class="scroll">
                    <p-table [value]="list" sortMode="multiple">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class='text-center' pSortableColumn="producto">
                                    <p-sortIcon field="name"></p-sortIcon>Producto
                                </th>
                                <th class='text-center' pSortableColumn="presentacion">
                                    <p-sortIcon field="name"></p-sortIcon>Presentación(LTS)
                                </th>
                                <th class='text-center' pSortableColumn="formato">
                                    <p-sortIcon field="name"></p-sortIcon>Formato
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-item>
                            <tr>
                                <td class='text-center'>{{item.producto}}</td>
                                <td class='text-center'>{{item.presentacion}}</td>
                                <td class='text-center'>{{item.formato}}</td>
                            </tr>

                        </ng-template>
                    </p-table>
                </div>
            </p-card>
            <p-paginator [rows]="rows" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"
                (onPageChange)="paginate($event)"></p-paginator>
        </div>
        <div class="field col-lg-12 col-xl-8 " *ngIf="progressBarValue!== 100">
            <p-card>
                <span class="text-center">{{progressBarText}}</span>
                <p-progressBar [value]="progressBarValue "></p-progressBar>
            </p-card>
        </div>
    </div>

</div>