<p-toast position="top-right" key="msg"></p-toast>
<p-dialog position="top" header="Carga de la planilla de parametros" [(visible)]="display">
    <form [formGroup]="formDatos" class="ui large form">
        <div class="p-fluid grid formgrid">
        
            <div class="field col-12 md:col-12 ">
                <label for="planilla">Email</label>
                <div class="flex">
                    <input type="text" pInputText class="inputfield w-media" formControlName="email">
                </div>
            </div>
            <div class="field col-12 md:col-12 ">
                <label for="planilla">Usuario</label>
                <div class="flex">
                    <input type="text" pInputText class="inputfield w-media" formControlName="username">
                </div>
            </div>
            <div class="field col-12 md:col-12 ">
                <label for="planilla">Password</label>
                <div class="flex">
                    <input type="password" pInputText class="inputfield w-media" formControlName="password">
                </div>
            </div>
            <div class="field col-12 md:col-12 ">
                <p-divider></p-divider>
            </div>

            <div class="field col-12 md:col-12 ">
                <button pButton pRipple type="button" class="float-right btn" icon="pi pi-check "
                    (click)="guardarNuevo()" label="Confirmar"></button>
            </div>
        </div>

    </form>
</p-dialog>
<div class="container">

    <div class="header">
        <h2>USUARIOS<br />
        </h2>

    </div>
    <p-card>

        <div class="text-center" *ngIf="archivoActual">
            <label>Archivo por defecto actual :</label>
            <span style="color:black">{{archivoActual.name}}</span>
           <!-- <p-button icon="pi pi-download" class="p-button-secondary m-1" (click)="download(archivoActual)"
                pTooltip="Descargar">
            </p-button>-->
        </div>
        <div class="text-center" *ngIf="isAdmin">

            <p-button (click)="showDialog()" label="Nuevo usuario" icon="pi pi-upload" class="p-button-secondary m-1 "
                pTooltip="Cargar nuevo usuario">
            </p-button>
        </div>
    </p-card>
    <p-card *ngIf="!spinner && results.length>0">

        <div class="scroll">
            <p-table [value]="list" sortMode="multiple">
                <ng-template pTemplate="header">
                    <tr>
                        <th class='text-center td-nombre' pSortableColumn="nombre">
                            <p-sortIcon field="name"></p-sortIcon>Email
                        </th>
                        <th class='text-center col-m' >Usuario</th>
                        <th class='text-center col-m'>Rol </th>                      
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item>
                    <tr>
                        <td class='text-center'>{{item.email}}</td>
                        <td class='text-center'>{{item.username}}</td>
                        <td class='text-center' *ngIf="item.roles.length>0"><p *ngFor="let rol of item.roles"> {{rol.nombre}}</p></td>
                        <td class='text-center' *ngIf="item.roles.length==0">Sin rol</td>
                    </tr>

                </ng-template>
            </p-table>
        </div>
    </p-card>
    <p-paginator  *ngIf="!spinner && results.length>0" [rows]="rows" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"
        (onPageChange)="paginate($event)"></p-paginator>
</div>

<div class="field col-lg-12 col-xl-12 " *ngIf="!spinner && results.length==0">
    <p-card>
        <div class="msg">
            Aún no hay resultados
        </div>
    </p-card>

</div>


<div class="field col-lg-12 col-xl-12 " *ngIf="spinner">
    <p-card class="text-center">
        <span>
            <p-progressSpinner></p-progressSpinner>
        </span>
        <br>
        <span class="text-center">{{progressBarText}}</span>
    </p-card>
</div>