import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/core/authentication/auth.service';

@Component({
  selector: 'app-recuperar-password',
  templateUrl: './recuperar-password.component.html',
  styleUrls: ['./recuperar-password.component.scss']
})
export class RecuperarPasswordComponent implements OnInit {


  keywords: any = [];
  formDatos = this.form.group({
    user: ['', Validators.required],
    pass: ['', Validators.required],
  });
  @Output() cerrar: EventEmitter<any> = new EventEmitter<any>();

  constructor(private router: Router,private form: FormBuilder,private messageService: MessageService, private authServices: AuthService) { }

  ngOnInit(): void {
  }

  confirmar() {
    let user = this.formDatos.get('user').value;
    this.formDatos.controls['user'].setValue(null)
    this.authServices.recuperarContraseña(user).subscribe(
      res => {
        this.messageService.add({key:'msg2',severity:'success', summary:'Hecho!', detail:'Se envio un mail para recuperar su contraseña'});
        this.cerrar.emit('close')
      },
      err => {
        let msg=err.slice(err.indexOf('Detail')+7,err.lenght)
        this.messageService.add({key:'msg2',severity:'error', summary:'error', detail:msg});
      })
  }


}
