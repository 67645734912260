import { HttpEventType } from '@angular/common/http';
import { TmplAstRecursiveVisitor } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ApiServerService } from 'src/app/core/services/api/api-server.service';

@Component({
  selector: 'app-reposicion',
  templateUrl: './reposicion.component.html',
  styleUrls: ['./reposicion.component.scss']
})
export class ReposicionComponent implements OnInit {
  formDatos = this.form.group({
    dias: [0],
    horas: [0],
    minutos: [0],
    camiones: [0],
    compartimento: [1],
    fileName: [{ value: "", disabled: true }],
    fileNameParametros: [{ value: "", disabled: true }],

  });
  filePath;
  filePathParametros;
  uploadedFiles: File;
  uploadedFilesParametros: File;
  display: boolean = false;
  spinner = false;
  camiones: any[] = [];
  planilla = "";
  parametrosExistentes: any[] = [];
  listCamiones: number[] = [];
  cargarArchivo = false;
  horaMinutos: String;
  errorCombo = false;
  formData = new FormData();

  constructor(private form: FormBuilder, private router: Router, private messageService: MessageService, private service: ApiServerService) { }

  ngOnInit(): void {
  }

  onUpload(event) {
    for (let file of event.files) {
      this.formDatos.controls['fileName'].setValue(file.name);
      this.filePath = file.name;
      this.uploadedFiles = file;

    }
    //  this.messageService.add({ key: 'msg', severity: 'success', summary: 'Hecho!', detail: 'El archivo se subio correctamente' });
  }
  onUploadParams(event) {
    for (let file of event.files) {
      this.formDatos.controls['fileNameParametros'].setValue(file.name);
      this.filePathParametros = file.name;
      this.uploadedFilesParametros = file;

    }
    //  this.messageService.add({ key: 'msg', severity: 'success', summary: 'Hecho!', detail: 'El archivo se subio correctamente' });
  }


  addItem() {
    var last = this.listCamiones.length;
    this.listCamiones.push(this.formDatos.get("compartimento").value)
    this.messageService.add({ key: 'msg', severity: 'success', summary: 'Listo!', detail: 'Compartimientos agregados' });
  }

  removeCamion(index) {
    this.listCamiones.splice(index, 1);
    this.messageService.add({ key: 'msg', severity: 'success', summary: 'Listo!', detail: 'Compartimientos eliminados' });

  }


  submit() {
    this.spinner = true;

    if (this.getData()) {

      this.service.optimizerPost(this.formData).subscribe(
        res => {
          this.messageService.add({ key: 'msg', severity: 'success', summary: 'Hecho!', detail: 'Datos guardados para iniciar proceso de validacion' });
          setTimeout(() => {
            this.messageService.add({ key: 'msg', severity: 'success', summary: 'Hecho!', detail: 'Redirigiendo...' });
            this.router.navigate(["/resultados/reposicion"]);
            this.spinner = false;
          }, 2000);
        }
        , error => {
          this.spinner = false;
          console.log(error)
          this.messageService.add({ key: 'msg', severity: 'error', summary: 'Ups!', detail: 'Ocurrio un error,intentelo nuevamente' });
        }
      )

    } else {
      this.spinner = false;
    }
  }

  getData() {
    let combo2 = (this.listCamiones.length > 0 && this.uploadedFiles && (this.formDatos.get("dias").value == 0 && (this.formDatos.get("horas").value == 0 || this.formDatos.get("minutos").value == 0)));
    if (combo2) {

      this.formData.append("userId", localStorage.getItem("userIdPae"));
      this.formData.append("type", "OPT");
      this.formData.append("compartmentsPerTruck", this.listCamiones.toString());
      this.formData.append("trucksQuantity", (this.listCamiones.length).toString());
      this.formData.append("fileSource", this.uploadedFiles, this.uploadedFiles.name);
      if (this.uploadedFilesParametros) {
        this.formData.append("parametros", this.uploadedFilesParametros, this.uploadedFilesParametros.name) + Math.random().toString();
      }

    }
    else {
      this.messageService.add({ key: 'msg', severity: 'warn', summary: 'Ups!', detail: 'Le faltan campos por completar' });
      return false;

    }
    return true;
  }
  async onUploadParamsExist(item) {
    let response = await fetch(item.url,{'mode': 'no-cors'},
    );
    let data = await response.blob();
    let metadata = {
      type: 'application/vnd.ms-excel'
    };
    let file = new File([data], item.name, metadata);
    this.formDatos.controls['fileNameParametros'].setValue(file.name);
    this.filePathParametros = file.name;
    this.uploadedFilesParametros = file;
    this.display = false;
  }

  download(item) {
    let filename = item.name
    let url = item.url
    var element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('download', filename);
    document.body.appendChild(element);
    element.click();
    this.messageService.add({ key: 'msg', severity: 'success', summary: 'Hecho!', detail: 'El archivo de resultado se descargo correctamente' });
  }
  planillaExistente() {
    this.service.parameterAll().subscribe(data => {
      this.parametrosExistentes = data.content;
      this.display = true;
    })
  }


}
