<p-toast position="top-right" key="msg"></p-toast>
<p-dialog position="top" [style]="{width: '60%'}"  header="Parametros existentes" [(visible)]="display">
    <p-table [value]="parametrosExistentes" sortMode="multiple">
        <ng-template pTemplate="header">
            <tr>
                <th class='text-center col-m' pSortableColumn="name">
                    <p-sortIcon field="name"></p-sortIcon>Nombre
                </th>
                <th class='text-center col-s'>Acciones </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
            <tr>
                <td class='text-center'>{{item.name}}</td>
        
                <td class="text-center">
                    <p-button icon="pi pi-plus" class="p-button-secondary m-1" (click)="onUploadParamsExist(item)"
                    pTooltip="Seleccionar">
                </p-button>
                    <p-button icon="pi pi-download" class="p-button-secondary m-1" (click)="download(item)"
                        pTooltip="Descargar">
                    </p-button>
                </td>
            </tr>

        </ng-template>
    </p-table>
</p-dialog>
<div class="container " *ngIf="!spinner">
    <div class="header">
        <h2>HISTORIAL<br /></h2>
    </div>
    <p-card class="card-container card-form min-width-500">
        <form [formGroup]="formDatos" class="ui large form">
            <div class="p-fluid grid formgrid">
                <div class="field col-12 md:col-12 ">
                    <label for="planilla">Planilla de entrada</label>
                    <div class="flex">
                        <input type="text" pInputText class="inputfield w-media" [value]="planilla">
                        <p-fileUpload class="ml-2" name="demo[]" chooseLabel="Seleccionar" [auto]="true"
                            (onSelect)="onUpload($event)" maxFileSize="1000000">
                            <ng-template pTemplate="content">
                                <ul *ngIf="uploadedFiles">
                                    <li >{{uploadedFiles.name}} - {{uploadedFiles.size}} bytes</li>
                                </ul>
                            </ng-template>
                        </p-fileUpload>
                    </div>

                </div>
                <div class="field col-12 md:col-12 ">
                    <label for="planilla">Parametros customizados</label>
                    <div class="flex">
                        <input type="text" pInputText  class="inputfield w-media" [value]="filePathParametros">
                        <p-fileUpload class="ml-2" name="demo2[]" chooseLabel="Seleccionar" [auto]="true"
                            (onSelect)="onUploadParams($event)" maxFileSize="1000000">
                            <ng-template pTemplate="content">
                                <ul *ngIf="uploadedFilesParametros">
                                    <li >{{uploadedFilesParametros.name}} - {{uploadedFilesParametros.size}} bytes</li>
                                </ul>
                            </ng-template>
                        </p-fileUpload>
                        <p-button class="ml-2"  label="Planilla existente" icon="pi pi-plus "
                        (click)="planillaExistente()"></p-button>
                    </div>

                </div>
                <div class="field col-12 md:col-12 ">
                    <label for="planilla">Planilla de historial</label>
                    <div class="flex">
                        <input type="text" pInputText class="inputfield w-media" [value]="planillaHistory">
                        <p-fileUpload class="ml-2" name="demo[]" chooseLabel="Seleccionar" [auto]="true"
                            (onSelect)="onUploadHistory($event)" maxFileSize="1000000">
                            <ng-template pTemplate="content">
                                <ul *ngIf="uploadedFilesHistory">
                                    <li >{{uploadedFilesHistory.name}} - {{uploadedFilesHistory.size}} bytes</li>
                                </ul>
                            </ng-template>
                        </p-fileUpload>
                    </div>

                </div>
                <div class="field col-12 md:col-12">
                    <label for="icon">Fecha</label>
                    <p-calendar formControlName="fecha" [showTime]="true" [showSeconds]="true" [showIcon]="true"
                        inputId="icon"></p-calendar>
                    <div *ngIf="formDatos.get('fecha').hasError('required') && formDatos.get('fecha').touched"
                        class="text-center">
                        <small class="p-error">Este campo es <strong>requerido</strong></small>
                    </div>
                </div>
                <div class="field col-12 md:col-12 ">
                    <button pButton pRipple type="button" class="float-right btn" icon="pi pi-check " (click)="submit()"
                        label="Confirmar"></button>
                </div>
            </div>

        </form>
    </p-card>
</div>


<div class="container" *ngIf="spinner">
    <p-card class="text-center">
        <span>
            <p-progressSpinner></p-progressSpinner>
        </span>
        <br>
        <span class="text-center">Procesando...</span>
    </p-card>
</div>