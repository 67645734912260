<p-toast position="top-right" key="msg"></p-toast>




<div class="container">
    <div class="p-fluid grid formgrid row">

        <div class="field col-12 ">
            <h2>Parametros - Editar - Productos<br /></h2>
        </div>
        <div class="field  col-lg-12 col-xl-12 " *ngIf="progressBarValue == 100">
            <p-card class="card-filtros">
                <form [formGroup]="formDatos" class="ui large form">
                    <div class="p-fluid grid formgrid">
                        <div class="field col-12 md:col-12 ">
                            <label for="planilla">Producto</label>
                            <div class="flex">
                                <input type="text" formControlName="producto" pInputText class="w-full">
                                <div *ngIf="formDatos.get('producto').hasError('required') && formDatos.get('producto').touched"
                                    class="text-center">
                                    <small class="p-error">Este campo es <strong>requerido</strong></small>
                                </div>
                            </div>
                        </div>
                        <div class="field col-12 md:col-12 ">
                            <label for="planilla">Presentación (LTS)</label>
                            <div class="flex">
                                <input type="text" formControlName="presentacion" pInputText>
                                <div *ngIf="formDatos.get('presentacion').hasError('required') && formDatos.get('presentacion').touched"
                                    class="text-center">
                                    <small class="p-error">Este campo es <strong>requerido</strong></small>
                                </div>
                            </div>
                        </div>
                        <div class="field col-12 md:col-12 ">
                            <label for="planilla">Formato</label>
                            <div class="flex">
                                <input type="text" formControlName="formato" pInputText>
                                <div *ngIf="formDatos.get('formato').hasError('required') && formDatos.get('formato').touched"
                                    class="text-center">
                                    <small class="p-error">Este campo es <strong>requerido</strong></small>
                                </div>
                            </div>
                        </div>
                       


                        <div class="field col-12 md:col-12 ">
                            <button pButton pRipple type="button" class="float-right btn" icon="pi pi-check "
                                (click)="submit()" label="Confirmar"></button>
                        </div>
                    </div>

                </form>
            </p-card>
        </div>

        <div class="field col-lg-12  " *ngIf="progressBarValue!== 100">
            <p-card>
                <span class="text-center">{{progressBarText}}</span>
                <p-progressBar [value]="progressBarValue "></p-progressBar>
            </p-card>
        </div>
    </div>

</div>