import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ApiServerService } from 'src/app/core/services/api/api-server.service';

@Component({
  selector: 'app-historial',
  templateUrl: './historial.component.html',
  styleUrls: ['./historial.component.scss']
})
export class HistorialComponent implements OnInit {
  formDatos = this.form.group({
    fecha: [new Date(), Validators.required],
    planilla: [''],
    filePathParametros: [{ value: "", disabled: true }],
    fileNameHistory: [{ value: "", disabled: true }],

  });
  planilla = "";
  filePathParametros = "";
  planillaHistory = "";
  uploadedFiles: File;
  uploadedFilesParametros: File;
  uploadedFilesHistory: File;
  parametrosExistentes: File;
  display = false;
  formData = new FormData();
  spinner = false;

  constructor(private form: FormBuilder,  private router: Router, private messageService: MessageService, private service: ApiServerService) {
    // document.getElementById("constr").style.display="block";
  }

  ngOnInit(): void {
  }
  onUploadParams(event) {
    for (let file of event.files) {
      this.formDatos.controls['filePathParametros'].setValue(file.name);
      this.filePathParametros = file.name;
      this.uploadedFilesParametros = file;

    }
    //  this.messageService.add({ key: 'msg', severity: 'success', summary: 'Hecho!', detail: 'El archivo se subio correctamente' });
  }
  onUpload(event) {
    let formData = new FormData();
    for (let file of event.files) {
      this.planilla = file.name;
      formData.append('planilla', file);
      this.uploadedFiles = (file);

    }
  }
  onUploadHistory(event) {
    let formData = new FormData();
    for (let file of event.files) {
      this.planillaHistory = file.name;
      formData.append('fileNameHistory', file);
      this.uploadedFilesHistory = (file);

    }
    this.messageService.add({ key: 'msg', severity: 'success', summary: 'Hecho!', detail: 'El archivo se subio correctamente' });
  }


  submit() {
    this.spinner = true;

    let valid = (this.uploadedFiles && this.uploadedFilesHistory && this.formDatos.get("fecha").status !== "INVALID");
    if (valid) {
      let fecha = (this.formDatos.get("fecha").value)
      var mes = ((fecha.getMonth() + 1) < 10) ? "0" + (fecha.getMonth() + 1) : (fecha.getMonth() + 1);
      var dia = ((fecha.getDate()) < 10) ? "0" + (fecha.getDate()) : (fecha.getDate());
      let date = fecha.getFullYear() + '-' + mes + '-' + dia;
      this.formData.append("userId", localStorage.getItem("userIdPae"));
      this.formData.append("fecha", date);
      this.formData.append("fileSource", this.uploadedFiles, this.uploadedFiles.name);
      this.formData.append("historialFile", this.uploadedFilesHistory, this.uploadedFilesHistory.name);
      if (this.uploadedFilesParametros) {
        this.formData.append("fileparametros", this.uploadedFilesParametros, this.uploadedFilesParametros.name);
      }
      this.service.historyPost(this.formData).subscribe(
        res => {
          this.messageService.add({ key: 'msg', severity: 'success', summary: 'Hecho!', detail: res });
          setTimeout(() => {
            this.messageService.add({ key: 'msg', severity: 'success', summary: 'Hecho!', detail: 'Redirigiendo...' });
            this.router.navigate(["/resultados/historial"]);
            this.spinner = false;
          }, 2000);
        },
        error => {
          console.log(error)
          this.spinner = false;
          this.messageService.add({ key: 'msg', severity: 'error', summary: 'Ups!', detail: 'Ocurrio un error,intentelo nuevamente' });
        })
    } else {
      this.spinner = false;
      this.messageService.add({ key: 'msg', severity: 'warn', summary: 'Ups!', detail: 'Le faltan campos por completar' });
    }

  }

  async onUploadParamsExist(item) {
    let response = await fetch(item.url, { 'mode': 'no-cors' },
    );
    let data = await response.blob();
    let metadata = {
      type: 'application/vnd.ms-excel'
    };
    let file = new File([data], item.name, metadata);
    this.formDatos.controls['filePathParametros'].setValue(file.name);
    this.filePathParametros = file.name;
    this.uploadedFilesParametros = file;
    this.display = false;
  }

  download(item) {
    let filename = item.name
    let url = item.url
    var element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('download', filename);
    document.body.appendChild(element);
    element.click();
    this.messageService.add({ key: 'msg', severity: 'success', summary: 'Hecho!', detail: 'El archivo de resultado se descargo correctamente' });
  }
  planillaExistente() {
    this.service.parameterAll().subscribe(data => {
      this.parametrosExistentes = data.content;
      this.display = true;
    })
  }
}