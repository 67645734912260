<p-toast position="top-right" key="msg"></p-toast>
<p-dialog position="top" header="Error" [style]="{width: '50vw'}" [(visible)]="display">
    {{error}}
</p-dialog>

<div class="container">
    <div class="p-fluid grid formgrid row">

        <div class="field col-12 md:col-12">
            <h2>Resultados - {{title}}<br /></h2>
        </div>
        <div class="field col-12 md:col-12">
            <div class="p-inputgroup">
                <input type="text" [(ngModel)]="palabra" pInputText placeholder="Ingresar nombre...">
                <button type="button" (click)="filtrar()" pButton pRipple icon="pi pi-search"
                    styleClass="p-button-warn"></button>
            </div>
        </div>

        <div class="field col-lg-12 col-xl-12 " *ngIf="resultUrl!='historial' && !spinner && results.length>0">
            <p-card>
                <div class="scroll">
                    <p-table [value]="list" sortMode="multiple">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class='text-center td-nombre' pSortableColumn="nombre">
                                    <!--<p-sortIcon field="name"></p-sortIcon>-->Nombre
                                </th>
                                <th class='text-center col-s' pSortableColumn="trucksQuantity">
                                    <!--<p-sortIcon field="name"></p-sortIcon>-->Camiones
                                </th>
                                <th class='text-center col-m' pSortableColumn="compartmentsPerTruck">
                                    <!--<p-sortIcon field="name"></p-sortIcon>-->Compartimientos
                                </th>
                                <th class='text-center col-m' pSortableColumn="fechaInicio">
                                    <!--<p-sortIcon field="name"></p-sortIcon>-->Fecha
                                </th>
                                <th class='text-center col-s' pSortableColumn="hora">
                                    <!--<p-sortIcon field="name"></p-sortIcon>-->Hora
                                </th>
                                <th class='text-center col-s' pSortableColumn="statusCompleted">
                                    <!--<p-sortIcon field="name"></p-sortIcon>-->Porcentaje de optimizacion
                                </th>
                                <th class='text-center col-m' pSortableColumn="hora">
                                    <!--<p-sortIcon field="name"></p-sortIcon>-->Estado
                                </th>
                                <th class='text-center col-s'>
                                    <!--<p-sortIcon field="name"></p-sortIcon>-->Acciones
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-item>
                            <tr>
                                <td class='text-center td-nombre'>{{item.nombre}} </td>
                                <td class='text-center'>{{item.trucksQuantity }}</td>
                                <td class='text-center'>{{item.compartmentsPerTruck }}</td>
                                <td class='text-center' *ngIf="item.fechaInicio">{{item.fechaInicio |
                                    date:'mediumDate'}}</td>
                                <td class='text-center' *ngIf="item.fechaInicio">{{item.fechaInicio| date: 'h:mm a'}}
                                </td>

                                <td class='text-center' *ngIf="!item.fechaInicio">-</td>
                                <td class='text-center' *ngIf="!item.fechaInicio">-</td>
                                <td class="text-center"
                                    *ngIf="!item.statusMessage.includes('Archivo de resultados disponible con nombre')">
                                    {{item.porcentaje}}%
                                </td>
                                <td class="text-center"
                                    *ngIf="item.statusMessage.includes('Archivo de resultados disponible con nombre')">
                                    100%
                                </td>
                                <td class='text-center'>
                                    <span class="status-procesando"
                                        *ngIf="!item.statusMessage.includes('ERROR') && item.statusCompleted=='Procesando'">Procesando...</span>
                                    <span class="status-validando"
                                        *ngIf="!item.statusMessage.includes('ERROR') && item.statusCompleted=='Validando'">Validando...</span>
                                    <span class="status-completo"
                                        *ngIf="!item.statusMessage.includes('ERROR') &&  item.statusCompleted=='Finalizado'">Finalizado</span>


                                    <button pTooltip="Ver detalles" style="max-width:90px;"
                                        *ngIf="item.statusMessage.includes('ERROR')" pButton pRipple type="button"
                                        class=" p-button-danger" (click)="showDialog(item.statusMessage)"
                                        label="Error"></button>

                                </td>
                                <td class="text-center">
                                    <!--<p-button  icon="pi pi-eye" class="p-button-secondary m-1"
                                            (click)="ir('/resultados/'+resultUrl+'/ver')" pTooltip="Ver detalles">
                                        </p-button>-->
                                       
                                    <p-button *ngIf="(item.statusCompleted=='Finalizado')"
                                        (click)="download(item)" icon="pi pi-download" class="p-button-secondary m-1 "
                                        pTooltip="Descargar">

                                    </p-button>
                                </td>
                            </tr>

                        </ng-template>
                    </p-table>
                </div>
            </p-card>
            <p-paginator [rows]="rows" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"
                (onPageChange)="paginate($event)"></p-paginator>
        </div>
        <div class="field col-lg-12 col-xl-12 " *ngIf="resultUrl=='historial' && !spinner && results.length>0">
            <p-card>
                <div class="scroll">
                    <p-table [value]="list" sortMode="multiple">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class='text-center td-nombre' pSortableColumn="nombre">
                                    <!--<p-sortIcon field="name"></p-sortIcon>-->Nombre
                                </th>
                      
                                <th class='text-center col-m' pSortableColumn="fechaInicio">
                                    <!--<p-sortIcon field="name"></p-sortIcon>-->Fecha
                                </th>
                                <th class='text-center col-s' pSortableColumn="hora">
                                    <!--<p-sortIcon field="name"></p-sortIcon>-->Hora
                                </th>
                                <th class='text-center col-s' pSortableColumn="statusCompleted">
                                    <!--<p-sortIcon field="name"></p-sortIcon>-->Porcentaje de optimizacion
                                </th>
                                <th class='text-center col-m' pSortableColumn="hora">
                                    <!--<p-sortIcon field="name"></p-sortIcon>-->Estado
                                </th>
                                <th class='text-center col-s'>
                                    <!--<p-sortIcon field="name"></p-sortIcon>-->Acciones
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-item>
                            <tr>
                                <td class='text-center td-nombre'>{{item.nombre}} </td>
                
                                <td class='text-center' *ngIf="item.fecha">{{item.fecha |
                                    date:'mediumDate'}}</td>
                                <td class='text-center' *ngIf="item.fecha">{{item.fecha| date: 'h:mm a'}}
                                </td>

                                <td class='text-center' *ngIf="!item.fecha">-</td>
                                <td class='text-center' *ngIf="!item.fecha">-</td>
                                <td class="text-center"
                                    *ngIf="item.statusMessage && !item.statusMessage.includes('Archivo de resultados disponible con nombre')">
                                    {{item.porcentaje}}%
                                </td>
                                <td class="text-center"
                                    *ngIf="item.statusMessage && item.statusMessage.includes('Archivo de resultados disponible con nombre')">
                                    100%
                                </td>
                                <td class='text-center'>
                                    <span class="status-procesando"
                                        *ngIf="item.statusMessage && !item.statusMessage.includes('ERROR') && item.statusCompleted=='Procesando'">Procesando...</span>
                                    <span class="status-validando"
                                        *ngIf="item.statusMessage && !item.statusMessage.includes('ERROR') && item.statusCompleted=='Validando'">Validando...</span>
                                    <span class="status-completo"
                                        *ngIf="item.statusMessage && !item.statusMessage.includes('ERROR') &&  item.statusCompleted=='Finalizado'">Finalizado</span>


                                    <button pTooltip="Ver detalles" style="max-width:90px;"
                                        *ngIf="item.statusMessage && item.statusMessage.includes('ERROR')" pButton pRipple type="button"
                                        class=" p-button-danger" (click)="showDialog(item.statusMessage)"
                                        label="Error"></button>

                                </td>
                                <td class="text-center">
                                    <!--<p-button  icon="pi pi-eye" class="p-button-secondary m-1"
                                            (click)="ir('/resultados/'+resultUrl+'/ver')" pTooltip="Ver detalles">
                                        </p-button>-->
                                       
                                    <p-button *ngIf="(item.statusCompleted=='Finalizado')"
                                        (click)="downloadHistory1(item)" icon="pi pi-download" class="p-button-secondary m-1 "
                                        pTooltip="Descargar Cumplimiento ">
                                    </p-button>
                                    <p-button *ngIf="(item.statusCompleted=='Finalizado')"
                                    (click)="downloadHistory2(item)" icon="pi pi-download" class="p-button-secondary m-1 "
                                    pTooltip="Descargar Agrupación">
                                </p-button>
                                </td>
                            </tr>

                        </ng-template>
                    </p-table>
                </div>
            </p-card>
            <p-paginator [rows]="rows" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"
                (onPageChange)="paginate($event)"></p-paginator>
        </div>
        <div class="field col-lg-12 col-xl-12 " *ngIf="!spinner && results.length==0">
            <p-card>
                <div class="msg">
                    Aún no hay resultados
                </div>
            </p-card>

        </div>







        <div class="field col-lg-12 col-xl-12 " *ngIf="spinner">
            <p-card class="text-center">
                <span>
                    <p-progressSpinner></p-progressSpinner>
                </span>
                <br>
                <span class="text-center">{{progressBarText}}</span>
            </p-card>
        </div>


    </div>
</div>