import { DatePipe } from '@angular/common';
import { HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ApiServerService } from 'src/app/core/services/api/api-server.service';

@Component({
  selector: 'app-batchcamion',
  templateUrl: './batchcamion.component.html',
  styleUrls: ['./batchcamion.component.scss']
})
export class BatchcamionComponent implements OnInit {

  formDatos = this.form.group({
    fecha: ["", Validators.required],
    camiones: ['', Validators.required],
    compartimento: [1, Validators.required],
    fileName: [{ value: "", disabled: true }],
    fileNameParametros: [{ value: "", disabled: true }],


  });
  cargarArchivo = false;
  camiones: number[] = [];
  filePath;
  filePathParametros;
  uploadedFiles: File;
  uploadedFilesParametros: File;
  listCamiones: any[] = [];
  errorCombo = false;
  progressBarValue = 100;
  spinner = false;
  parametrosExistentes: any[] = [];
  display:boolean=false;
  minDateValue = new Date();
  constructor(private form: FormBuilder, private router: Router, private messageService: MessageService, private service: ApiServerService) {
    this.addDays();
  }

  ngOnInit(): void {

  }


  onUpload(event) {
    for (let file of event.files) {
      this.formDatos.controls['fileName'].setValue(file.name);
      this.filePath = file.name;
      this.uploadedFiles = file;

    }
    //  this.messageService.add({ key: 'msg', severity: 'success', summary: 'Hecho!', detail: 'El archivo se subio correctamente' });
  }

  onUploadParams(event) {
    for (let file of event.files) {
      this.formDatos.controls['fileNameParametros'].setValue(file.name);
      this.filePathParametros = file.name;
      this.uploadedFilesParametros = file;

    }
    //  this.messageService.add({ key: 'msg', severity: 'success', summary: 'Hecho!', detail: 'El archivo se subio correctamente' });
  }
  addItem() {
    this.listCamiones.push(this.formDatos.get("compartimento").value)
    this.messageService.add({ key: 'msg', severity: 'success', summary: 'Listo!', detail: 'Compartimientos agregados' });

  }

  removeCamion(index) {
    this.listCamiones.splice(index, 1);
    this.messageService.add({ key: 'msg', severity: 'success', summary: 'Listo!', detail: 'Compartimientos eliminados' });

  }

  submit() {
    this.spinner = true;
    let data = this.getData();
    if (data) {
      this.service.optimizerPost(data).subscribe(
        res => {
          this.messageService.add({ key: 'msg', severity: 'success', summary: 'Hecho!', detail: 'Datos guardados para iniciar proceso de validacion' });
          setTimeout(() => {
            this.messageService.add({ key: 'msg', severity: 'success', summary: 'Hecho!', detail: 'Redirigiendo...' });
            this.router.navigate(["/resultados/batchcamion"]);
            this.spinner = false;
          }, 2000);
        }
        , error => {
          this.spinner = false;
          console.log(error)
          this.messageService.add({ key: 'msg', severity: 'error', summary: 'Ups!', detail: 'Ocurrio un error,intentelo nuevamente' });
        }
      )
    }
  }


  getData() {
    var formData = new FormData();
    let combo1 = (this.listCamiones.length > 0 && this.uploadedFiles && this.formDatos.get("fecha").status !== "INVALID");
    if (combo1) {
      this.errorCombo = false;
      let fecha = (this.formDatos.get("fecha").value)
      var mes = ((fecha.getMonth() + 1) < 10) ? "0" + (fecha.getMonth() + 1) : (fecha.getMonth() + 1);
      var dia = ((fecha.getDate()) < 10) ? "0" + (fecha.getDate()) : (fecha.getDate());
      let date = fecha.getFullYear() + '-' + mes + '-' + dia;

      formData.append("userId", localStorage.getItem("userIdPae"));
      formData.append("type", "OPT_BATCH");
      formData.append("compartmentsPerTruck", this.listCamiones.toString());
      formData.append("trucksQuantity", (this.listCamiones.length).toString());
      formData.append("fileSource", this.uploadedFiles, this.uploadedFiles.name);
      if (this.uploadedFilesParametros) {
        formData.append("parametros", this.uploadedFilesParametros, this.uploadedFilesParametros.name) + Math.random().toString();
      }
      formData.append("batchDeadline", date);
      return formData;
    }
    else {
      this.spinner = false;
      this.messageService.add({ key: 'msg', severity: 'warn', summary: 'Ups!', detail: 'Le faltan campos por completar' });
    }
  }

  addDays() {
    this.minDateValue.setDate(this.minDateValue.getDate() + 1);
  }

  async onUploadParamsExist(item) {
    let response = await fetch(item.url,{'mode': 'no-cors'},
    );
    let data = await response.blob();
    let metadata = {
      type: 'application/vnd.ms-excel'
    };
    let file = new File([data], item.name, metadata);
    this.formDatos.controls['fileNameParametros'].setValue(file.name);
    this.filePathParametros = file.name;
    this.uploadedFilesParametros = file;
    this.display = false;
  }

  download(item) {
    let filename = item.name
    let url = item.url
    var element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('download', filename);
    document.body.appendChild(element);
    element.click();
    this.messageService.add({ key: 'msg', severity: 'success', summary: 'Hecho!', detail: 'El archivo de resultado se descargo correctamente' });
  }
  planillaExistente() {
    this.service.parameterAll().subscribe(data => {
      this.parametrosExistentes = data.content;
      this.display = true;
    })
  }

}
