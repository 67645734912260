import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-resultados-batchcamion-ver',
  templateUrl: './resultados-batchcamion-ver.component.html',
  styleUrls: ['./resultados-batchcamion-ver.component.scss']
})
export class ResultadosBatchcamionVerComponent implements OnInit {
  list: any[] = []
  list2: any[] = []
  // totalRecords: number;
  // rows = 10;
  // page = 0;
  progressBarValue = 0;
  progressBarText = "Cargando datos...";
  palabra = "";

  Archivoes: any[] = []

  selectedArchivo: any;

  constructor(private router: Router) {
    this.list.push({ numero: 1 });
    this.list.push({ numero: 2 });
    this.list.push({ numero: 3 });
    this.list.push({ numero: 4 });
    this.list.push({ numero: 5 });

    this.list2.push({ numero: 1 });
    this.list2.push({ numero: 2 });
    this.list2.push({ numero: 3 });

    this.Archivoes.push({ name: 'Archivo 1', code: '1' });
    this.Archivoes.push({ name: 'Archivo 2', code: '1' });
    this.Archivoes.push({ name: 'Archivo 3', code: '1' });




    // this.totalRecords = this.list.length;

    this.progressBarValue = 0;
    let interval = setInterval(() => {
      this.progressBarValue = this.progressBarValue + Math.floor(Math.random() * 10) + 1;
      if (this.progressBarValue >= 100) {
        this.progressBarValue = 100;
        clearInterval(interval);
      }
    }, 100);
  }

  ngOnInit(): void {
  }

  paginate(event) {
    alert("cambio de pagina")

  }

  ir(url) {
    this.router.navigate([url]);
  }

  filtrar() {
    this.progressBarText = "Filtrando...";
    this.progressBarValue = 0;
    let interval = setInterval(() => {
      this.progressBarValue = this.progressBarValue + Math.floor(Math.random() * 10) + 1;
      if (this.progressBarValue >= 100) {
        this.progressBarValue = 100;
        clearInterval(interval);
      }
    }, 100);

  }

  cambiarArchivo(nombre) {
    this.progressBarText = "Filtrando por Archivo " + nombre + "...";
    this.progressBarValue = 0;
    let interval = setInterval(() => {
      this.progressBarValue = this.progressBarValue + Math.floor(Math.random() * 10) + 1;
      if (this.progressBarValue >= 100) {
        this.progressBarValue = 100;
        clearInterval(interval);
      }
    }, 100);
  }
}