import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ShellModule} from '../modules/shell/shell.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import { SharedModule } from '../shared/components/shared.module';
import { HomeModule } from '../modules/home/home.module';
import { HomeRoutingModule } from '../modules/home/home-routing.module';
import { LoginRoutingModule } from '../modules/login/login-routing.module';
import { LoginModule } from '../modules/login/login.module';
import { ShellRoutingModule } from '../modules/shell/shell-routing.module';

@NgModule({

  imports:[
    CommonModule, 
    BrowserAnimationsModule, 
    ShellRoutingModule,
    SharedModule,
    HomeRoutingModule,
    LoginRoutingModule,

   ],
   
  exports:[
  
    ShellModule,
    HomeModule,
    LoginModule,
  
  ],
   
  declarations: []
})
export class CoreModule {
}
