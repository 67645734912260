import { style } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/core/authentication/auth.service';


@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit {
  logins: boolean;
  isAdmin: boolean;
  username: string
  rol: string
  constructor(private authService: AuthService, private router: Router, private messageService: MessageService) {   
  }

  ngOnInit() {

  }

  click() {
    var sidebar = document.querySelector(".sidebar");
    sidebar.classList.toggle("active");


  }

  login() {
    return this.authService.isLoggedIn()
  }
  clickSearch() {
    var sidebar = document.querySelector(".sidebar");
    sidebar.classList.toggle("active");
  }

  logout() {
    this.messageService.add({ key: 'msg', severity: 'success', summary: 'Hecho!', detail: 'Cerrando sesión... ' });
    setTimeout(() => {
      localStorage.removeItem("userIdPae");
      localStorage.removeItem("token");
      localStorage.removeItem('userName')
      localStorage.removeItem('userRole')
      var home = document.getElementById("home");
      home.style.left = "0px"
      this.router.navigate(["/login"]);
    }, 2000);
  }


  ngAfterViewChecked(){
    this.isAdmin = localStorage.getItem("userRole") == 'ROLE_ADMIN';
    this.username=localStorage.getItem("userName")
    if(this.isAdmin){
      this.rol="Administrador"
    }else{
      this.rol="Operador"

    }
  }
}
