import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule, ActivatedRoute } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
const routes:Routes = [
  {path:'login', component:LoginComponent },
 ];
 
 @NgModule({
   imports:[RouterModule.forChild(routes)],
   exports:[RouterModule]
 })
export class LoginRoutingModule { }
