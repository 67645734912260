import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-resultados-reposicion-ver',
  templateUrl: './resultados-reposicion-ver.component.html',
  styleUrls: ['./resultados-reposicion-ver.component.scss']
})
export class ResultadosReposicionVerComponent implements OnInit {

  list: any[] = []
  list2: any[] = []
 // totalRecords: number;
 // rows = 10;
 // page = 0;
  progressBarValue = 0;
  progressBarText = "Cargando datos...";
  palabra="";
  columnas = [
    { name: 'Ver Programa Para Cada camión', value: '1' },
    { name: 'Ver Carga Almacén', value: 2 },
    { name: 'Descargar OT', value: 3 }
  ];
  columnasSelected='1';
  camiones: any[] = []

  selectedCamion: any;

  constructor(private router: Router) {
    this.list.push({numero:1});
    this.list.push({numero:2});
    this.list.push({numero:3});
    this.list.push({numero:4});
    this.list.push({numero:5});

    this.list2.push({numero:1});
    this.list2.push({numero:2});
    this.list2.push({numero:3});

    this.camiones.push({name: 'camion 1', code: '1'});
    this.camiones.push({name: 'camion 2', code: '1'});
    this.camiones.push({name: 'camion 3', code: '1'});


  
  
   // this.totalRecords = this.list.length;

    this.progressBarValue = 0;
    let interval = setInterval(() => {
      this.progressBarValue = this.progressBarValue + Math.floor(Math.random() * 10) + 1;
      if (this.progressBarValue >= 100) {
        this.progressBarValue = 100;
        clearInterval(interval);
      }
    }, 100);
  }

  ngOnInit(): void {
  }

  paginate(event) {
    alert("cambio de pagina")

  }

  ir(url) {
    this.router.navigate([url]);
  }

  filtrar() {
    this.progressBarText = "Filtrando...";
    this.progressBarValue = 0;
    let interval = setInterval(() => {
      this.progressBarValue = this.progressBarValue + Math.floor(Math.random() * 10) + 1;
      if (this.progressBarValue >= 100) {
        this.progressBarValue = 100;
        clearInterval(interval);
      }
    }, 100);

  }

  cambiar(){
    console.log(this.columnasSelected)
  }

  cambiarCamion(nombre){
    this.progressBarText = "Filtrando por camion "+ nombre +"...";
    this.progressBarValue = 0;
    let interval = setInterval(() => {
      this.progressBarValue = this.progressBarValue + Math.floor(Math.random() * 10) + 1;
      if (this.progressBarValue >= 100) {
        this.progressBarValue = 100;
        clearInterval(interval);
      }
    }, 100);
  }
}
