
<div class="p-d-flex p-flex-column">
  
    <div class='p-mb-12 ml-4  mr-4'>

        <form [formGroup]="formDatos" class="ui large error form">
            <div class="grid">

                <div class="field col-12">
                    <label for="email">Usuario</label>

                    <input type="text" pInputText formControlName="user" class="inputfield w-full">
                    <div *ngIf="formDatos.get('user').hasError('required') && formDatos.get('user').touched"
                        class="text-center">
                        <small class="p-error">Este campo es <strong>requerido</strong></small>
                    </div>

                </div>
                <!--<div class="field col-12">
                    <label for="email">Contraseña</label>

                    <input type="password" pInputText formControlName="pass" class="inputfield w-full">
                    <div *ngIf="formDatos.get('pass').hasError('required') && formDatos.get('pass').touched"
                        class="text-center">
                        <small class="p-error">Este campo es <strong>requerido</strong></small>
                    </div>
                   
                </div>-->


                <div class="field col-12">
                    <span class="p-float-label">
                        <button pButton  [disabled]="formDatos.invalid"  (click)='confirmar()' class='m-2'>Confirmar</button>
                     
                    </span>
                </div>


            </div>
        </form>

    </div>

</div>