import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ApiServerService } from 'src/app/core/services/api/api-server.service';

@Component({
  selector: 'app-parametros',
  templateUrl: './parametros.component.html',
  styleUrls: ['./parametros.component.scss']
})
export class ParametrosComponent implements OnInit {
  list: any[] = []
  display: boolean = false;
  totalRecords: number;
  rows = 10;
  page = 0;
  filePath;
  first = 0;
  uploadedFiles: File;
  spinner = false;
  results: any[] = []
  paginateData: any;
  formDatos = this.form.group({
    fileName: [{ value: "", disabled: true }],
    checked: [{ value: false }],
    descripcion: [],
  });
  archivoActual: any;
  isAdmin:boolean;
  constructor(private service: ApiServerService, private router: Router, private form: FormBuilder, private messageService: MessageService) {
    this.isAdmin=localStorage.getItem("userRole") == 'ROLE_ADMIN';
    console.log(this.isAdmin, localStorage.getItem("userRole"))
    this.formDatos.controls["checked"].setValue(false)
    this.recargar()
  }

  ngOnInit(): void {
  }

  paginate(event) {
    this.paginateData = event;
    this.first = this.first;
    this.totalRecords = this.results.length;
    this.list = this.results.slice(event.first, event.first + event.rows)
  }

  ir(url) {
    this.router.navigate([url]);
  }
  recargar() {
    this.service.parameterFindAll(this.page).subscribe(data => {
      console.log(data)
      this.results = data.content;
      this.archivoActual = this.results.find(d => d.active)
      this.paginate({ page: 0, first: this.first, rows: this.rows });
      this.totalRecords = this.results.length;
    })
  }
  guardarNuevo() {
    var formData = new FormData();
    if (this.uploadedFiles) {
      this.spinner = true;
      formData.append("userId", localStorage.getItem("userIdPae"));
      formData.append("description", (!this.formDatos.get("descripcion").value) ? "" : this.formDatos.get("descripcion").value);
      formData.append("active", this.formDatos.get("checked").value);
      formData.append("name", "tatiparametros");
      formData.append("file", this.uploadedFiles, this.uploadedFiles.name);
      this.service.newParameter(formData).subscribe(
        res => {
          this.messageService.add({ key: 'msg', severity: 'success', summary: 'Hecho!', detail: res });
          this.spinner = false;
          this.display = false;
          this.formDatos.reset()
          this.recargar();
        }
        , error => {
          this.spinner = false;
          console.log(error)
          this.messageService.add({ key: 'msg', severity: 'error', summary: 'Ups!', detail: 'Ocurrio un error,intentelo nuevamente' });
        }
      )
    } else {
      this.messageService.add({ key: 'msg', severity: 'warn', summary: 'Ups!', detail: 'Debe seleccionar un archivo' })
    }
  }
  onUpload(event) {
    for (let file of event.files) {
      this.formDatos.controls['fileName'].setValue(file.name);
      this.filePath = file.name;
      this.uploadedFiles = file;

    }
    //  this.messageService.add({ key: 'msg', severity: 'success', summary: 'Hecho!', detail: 'El archivo se subio correctamente' });
  }

  showDialog() {
    document.getElementById("sidebar").style.zIndex="97"
    this.display = true;
  }

  onChange(item) {
    var formData = new FormData();
    formData.append("parametersId", item.id);
    this.service.parameterEdit(formData).subscribe(
      res => {
        this.messageService.add({ key: 'msg', severity: 'success', summary: 'Hecho!', detail: "Archivo "+item.name + " asignado correctamente" });
        this.spinner = false
        this.recargar();
      }
      , error => {
        this.spinner = false;
        this.messageService.add({ key: 'msg', severity: 'error', summary: 'Ups!', detail: 'Ocurrio un error,intentelo nuevamente' });
      }
    )

  }


  download(item) {

    let filename = item.name
    let url = item.url
    var element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('download', filename);
    document.body.appendChild(element);
    element.click();
    this.messageService.add({ key: 'msg', severity: 'success', summary: 'Hecho!', detail: 'El archivo de resultado se descargo correctamente' });
  }

  OnDestroy(){
    document.getElementById("sidebar").style.zIndex="99"
  }

}