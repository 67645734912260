<p-toast position="top-right" key="msg"></p-toast>

<div class="container">
    <div class="p-fluid grid formgrid row">

        <div class="field col-12 md:col-12">
            <h2>Resultados - Reposición - Camión<br /></h2>
        </div>
        <div class="field col-12 md:col-12 ">
            <p-selectButton (click)="cambiar()" [options]="columnas" [(ngModel)]="columnasSelected" optionLabel="name"
                optionValue="value"></p-selectButton>

        </div>
        <div class="field col-12 md:col-12 ">
            <p-divider></p-divider>
        </div>
        <div class="ml-5">
            <p-dropdown [options]="camiones" [(ngModel)]="selectedCamion" optionLabel="name" [filter]="true"
                filterBy="name" [showClear]="true" placeholder="Seleciona un Camión">
                <ng-template pTemplate="selectedItem">
                    <div class="country-item country-item-value" *ngIf="selectedCamion">
                        <div>{{selectedCamion.name}}</div>
                    </div>
                </ng-template>
                <ng-template let-country pTemplate="item">
                    <div class="country-item">
                        <div>{{country.name}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
        </div>
        <div class="field col-lg-12 col-xl-12 " *ngIf="progressBarValue== 100 && columnasSelected=='1'">

            <p-card>
                <div class="scroll">
                    <p-table [value]="list" sortMode="multiple">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class='text-center' pSortableColumn="ut">
                                    <p-sortIcon field="name"></p-sortIcon>UT
                                </th>
                                <th class='text-center' pSortableColumn="distrito">
                                    <p-sortIcon field="name"></p-sortIcon>Distrito
                                </th>
                                <th class='text-center' pSortableColumn="zona">
                                    <p-sortIcon field="name"></p-sortIcon>Zona
                                </th>
                                <th class='text-center' pSortableColumn="estpias">
                                    <p-sortIcon field="name"></p-sortIcon>EST-PIAS
                                </th>
                                <th class='text-center' pSortableColumn="pozolugar">
                                    <p-sortIcon field="name"></p-sortIcon>Pozo-Lugar
                                </th>
                                <th class='text-center' pSortableColumn="producto">
                                    <p-sortIcon field="name"></p-sortIcon>Producto
                                </th>
                                <th class='text-center' pSortableColumn="metodo">
                                    <p-sortIcon field="name"></p-sortIcon>Metodo
                                </th>
                                <th class='text-center' pSortableColumn="carga">
                                    <p-sortIcon field="name"></p-sortIcon>Carga (LTS)
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-item>
                            <tr>
                                <td class='text-center'>{{item.ut}}</td>
                                <td class='text-center'>{{item.distrito}}</td>
                                <td class='text-center'>{{item.zona}}</td>
                                <td class='text-center'>{{item.estpias}}</td>
                                <td class='text-center'>{{item.pozolugar}}</td>
                                <td class='text-center'>{{item.producto}}</td>
                                <td class='text-center'>{{item.metodo}}</td>
                                <td class='text-center'>{{item.carga}}</td>
                            </tr>

                        </ng-template>
                    </p-table>
                </div>
            </p-card>
            <!--<p-paginator [rows]="rows" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"
                (onPageChange)="paginate($event)"></p-paginator>-->
        </div>

        <div class="field col-lg-12 col-xl-12 " *ngIf="progressBarValue== 100 && columnasSelected=='2'">

            <p-card>
                <div class="scroll">
                    <p-table [value]="list2" sortMode="multiple">
                        <ng-template pTemplate="header">
                            <tr>

                                <th class='text-center' pSortableColumn="producto">
                                    <p-sortIcon field="name"></p-sortIcon>Producto
                                </th>
                                <th class='text-center' pSortableColumn="cantidad">
                                    <p-sortIcon field="name"></p-sortIcon>Cantidad
                                </th>
                                <th class='text-center' pSortableColumn="presentacion">
                                    <p-sortIcon field="name"></p-sortIcon>Presentación (LTS)
                                </th>
                                <th class='text-center' pSortableColumn="remanente">
                                    <p-sortIcon field="name"></p-sortIcon>Remanente (LTS)
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-item>
                            <tr>
                                <td class='text-center'>{{item.producto}}</td>
                                <td class='text-center'>{{item.cantidad}}</td>
                                <td class='text-center'>{{item.presentacion}}</td>
                                <td class='text-center'>{{item.remanente}}</td>

                            </tr>

                        </ng-template>
                    </p-table>
                </div>
            </p-card>
            <!--<p-paginator [rows]="rows" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"
                (onPageChange)="paginate($event)"></p-paginator>-->
        </div>




        <div class="field col-lg-12 col-xl-12 " *ngIf="progressBarValue!== 100">
            <p-card>
                <span class="text-center">{{progressBarText}}</span>
                <p-progressBar [value]="progressBarValue "></p-progressBar>
            </p-card>
        </div>
    </div>