import { Component, OnInit } from '@angular/core';
import { FormBuilder, PatternValidator, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { ApiServerService } from 'src/app/core/services/api/api-server.service';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.scss']
})
export class UsuariosComponent implements OnInit {

  list: any[] = []
  display: boolean = false;
  totalRecords: number;
  rows = 10;
  page = 0;
  filePath;
  first = 0;
  uploadedFiles: File;
  spinner = false;
  results: any[] = []
  progressBarText = "Cargando datos...";
  paginateData: any;
  formDatos = this.form.group({
    //  email:  ['', [Validators.required,Validators.pattern("/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/")]],
    email: [''],
    username: [''],
    password: [''],
  });
  archivoActual: any;
  isAdmin: boolean;
  constructor(private authservice: AuthService, private service: ApiServerService, private router: Router, private form: FormBuilder, private messageService: MessageService) {
    this.isAdmin = localStorage.getItem("userRole") == 'ROLE_ADMIN';
    this.recargar()
  }

  ngOnInit(): void {
  }

  paginate(event) {
    this.paginateData = event;
    this.first = this.first;
    this.totalRecords = this.results.length;
    this.list = this.results.slice(event.first, event.first + event.rows)
  }

  ir(url) {
    this.router.navigate([url]);
  }
  recargar() {
    this.spinner = true;
    this.service.userFindAll().subscribe(data => {
      this.results = data.content;
      this.archivoActual = this.results.find(d => d.active)
      this.paginate({ page: 0, first: this.first, rows: this.rows });
      this.totalRecords = this.results.length;
      this.spinner = false;
    })
  }
  guardarNuevo() {
    let mail = this.formDatos.get('email').value;
    let user = this.formDatos.get('username').value;
    let password = this.formDatos.get('password').value;
    if (mail && user && password) {
      this.spinner = true;
      this.service.newUser({ email: mail, username: user, password: password }).subscribe(
        res => {
          this.messageService.add({ key: 'msg', severity: 'success', summary: 'Hecho!', detail: res });
          this.spinner = false;
          this.display = false;
          this.recargar();
          this.formDatos.reset();
        }
        , error => {
          this.spinner = false;
          console.log(error)
          this.messageService.add({ key: 'msg', severity: 'error', summary: 'Ups!', detail: error.error });
        }
      )
    } else {
      this.messageService.add({ key: 'msg', severity: 'warn', summary: 'Ups!', detail: 'Le faltan campos por completar' })

      //this.messageService.add({ key: 'msg', severity: 'warn', summary: 'Ups!', detail: 'Debe ingresar un email valido' })

    }
  }
  onUpload(event) {
    for (let file of event.files) {
      this.formDatos.controls['fileName'].setValue(file.name);
      this.filePath = file.name;
      this.uploadedFiles = file;

    }
    //  this.messageService.add({ key: 'msg', severity: 'success', summary: 'Hecho!', detail: 'El archivo se subio correctamente' });
  }

  showDialog() {
    document.getElementById("sidebar").style.zIndex = "97"
    this.display = true;
  }





  OnDestroy() {
    document.getElementById("sidebar").style.zIndex = "99"
  }

}