<p-toast position="top-right" key="msg"></p-toast>

<div class="container">
    <div class="header">
        <h2>RESULTADOS<br /></h2>
    </div>
    <p-card class="card-container">
        <div class="p-fluid grid formgrid">
            <div class="col-lg-12 col-xl-12 container-result">
                <p-button icon="pi pi-arrow-right" label="REPOSICIÓN" class="p-button-secondary m-1"
                    (click)="ir('/resultados/reposicion')" pTooltip="Ver lista de reposiciones">
                </p-button>

                <p-button icon="pi pi-arrow-right" label="BATCH/CAMIÓN" class="p-button-secondary m-1"
                    (click)="ir('/resultados/batchcamion')" pTooltip="Ver lista batch /camion">
                </p-button>
                
                <p-button icon="pi pi-arrow-right" label="HISTORIAL" class="p-button-secondary m-1"
                    (click)="ir('/resultados/historial')" pTooltip="Ver lista de historial">
                </p-button>
            </div>

        </div>

    </p-card>
</div>