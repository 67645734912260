<p-toast position="top-right" key="msg"></p-toast>
<div id="sidebar" class="sidebar active" *ngIf="login()">
    <div class="logo_content">
        <div class="logo">
            <div class="logo_name">Menu</div>
        </div>
        <i class='bx bx-menu' id="btn" (click)="click()"></i>
    </div>
    <ul class="nav_list" *ngIf="login()">
        <li>
            <a href="/home">
                <i class='bx bx-home'></i>
                <span class="links_name">Home</span>
            </a>
            <span class="tooltip">Home</span>
        </li>
        <li>
            <a href="/reposicion">
                <i class='bx bx-package'></i>
                <span class="links_name">Reposición</span>
            </a>
            <span class="tooltip">Reposición</span>
        </li>
        <li>
            <a href="/batchcamion">
                <i class='bx bx-bus'></i>
                <span class="links_name">Batch/Camión</span>
            </a>
            <span class="tooltip">Batch/Camión</span>
        </li>
        <li>
            <a href="/historial">
                <i class='bx bx-history'></i>
                <span class="links_name">Historial</span>
            </a>
            <span class="tooltip">Historial</span>
        </li>
        <li>
            <a href="/parametros">
                <i class='bx bx-slider'></i>
                <span class="links_name">Parametros</span>
            </a>
            <span class="tooltip">Parametros</span>
        </li>
        <li>
            <a href="/resultados">
                <i class='bx bx-task'></i>
                <span class="links_name">Resultados</span>
            </a>
            <span class="tooltip">Resultados</span>
        </li>
        <li *ngIf="isAdmin">
            <a href="/usuarios">
                <i class='bx bx-user'></i>
                <span class="links_name">Usuarios</span>
            </a>
            <span class="tooltip">Usuarios</span>
        </li>
        <li>
            <a href="/verMas">
                <i class='bx bx-help-circle'></i>
                <span class="links_name">Ver mas</span>
            </a>
            <span class="tooltip">Ver mas</span>
        </li>

    </ul>
    <div class="profile_content" *ngIf="login()">
        <div class="profile">
            <div class="profile_details">
                <img src="./assets/img/profile.jpg" alt="profile image">
                <div class="name_job">
                    <div class="name">{{username}}</div>
                    <div class="job">{{rol}}</div>
                </div>
            </div>
            <a (click)="logout()" ><i class="bx bx-log-out" id="log_out"></i></a>
        </div>
    </div>
</div>
<div class="contruccion" id="constr">
        <div class="title-c ">PAGINA EN CONSTRUCCIÓN</div>
   
</div>
<div class="home_content " id="home">

    <div class="body">
        <app-main style='width:100%'></app-main>

    </div>
    <div class="footer">
        <div></div>
    </div>
</div>