import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimengModule } from 'src/app/shared/primeng/primeng.module';
import { LoginComponent } from './pages/login/login.component';
import { RecuperarPasswordComponent } from './components/recuperar-password/recuperar-password.component';
@NgModule({
  declarations: [
    RecuperarPasswordComponent,
    LoginComponent,
  ],
  imports: [
    PrimengModule,
    CommonModule
  ]
})
export class LoginModule { }
