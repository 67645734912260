<!---->


<div class="container">
    <div class="p-fluid grid formgrid row">
        <div class="field col-12 md:col-12" style="padding-top:8%;">
            <p-card class="card-container card-form min-width-500">

                <p>
                    Esta aplicación, desarrollada por CANDOIT optimiza la distribución de químicos en
                    yacimiento. <br>
                </p>
                <p style="text-indent: 20px; ">Se implementa el algoritmo genético como herramienta de optimización. El
                    algoritmo busca la mejor combinación que satisfaga las restricciones y brinde las
                    mejores bondades como solución, en términos de evitar stockouts, camino recorrido,
                    no retorno de producto a base, etcétera.</p>
                <div class="col-12 md:col-12 " style="text-align: end;">
                    <img src="./assets/img/logo.png" id="img" alt="">
                </div>
            </p-card>

        </div>
    </div>
</div>