<p-toast position="top-right" life='40000' key="msg"></p-toast>

<div class="container" *ngIf="progressBarValue == 100">
 
    <div class="field col-12 md:col-12 text-center">
        <h2>Sistema de optimización logística<br /></h2>
    </div>
    <p-card class="card-container card-login">
        <form [formGroup]="formDatos" class="ui large form">
            <div class="p-fluid grid formgrid">
                <div class="col-12 md:col-12 text-center">
                    <img src="./assets/img/logo.png" id="img" alt="">
                </div>
                <div class="col-12 md:col-12 text-center">
                    <label for="">Usuario</label>

                    <div class="">
                        <input type="text" formControlName="user" pInputText placeholder="username@gmail.com.ar">
                        <button type="button" pButton pRipple icon="pi pi-user" styleClass="p-button-warn"></button>
                    </div>
                    <div *ngIf="formDatos.get('user').hasError('required') && formDatos.get('user').touched"
                        class="text-center">
                        <small class="p-error">Este campo es <strong>requerido</strong></small>
                    </div>
                </div>

                <div class="col-12 md:col-12 mt-4 text-center">
                    <label for="">Contraseña</label>
                    <div class="">
                        <input type="password" pInputText formControlName="pass" placeholder="********">
                        <button type="button" pButton pRipple icon="pi pi-lock" styleClass="p-button-warn"></button>
                    </div>
                    <div *ngIf="formDatos.get('pass').hasError('required') && formDatos.get('pass').touched"
                        class="text-center">
                        <small class="p-error">Este campo es <strong>requerido</strong></small>
                    </div>
                </div>

                <div class="field col-12 md:col-12 mt-3 ">
                    <button pButton pRipple type="button" class="float-right btn" icon="pi pi-check " (click)="submit()"
                        label="Confirmar"></button>
                </div>
            </div>
        </form>
    </p-card>
</div>


<div class="container" *ngIf="progressBarValue != 100">
    <p-card>
        <span class="text-center">Procesando...</span>
        <p-progressBar [value]="progressBarValue "></p-progressBar>
    </p-card>
</div>