<p-toast position="top-right" life='40000' key="msg"></p-toast>
<p-dialog position="top" [style]="{width: '60%'}"  header="Parametros existentes" [(visible)]="display">
        <p-table [value]="parametrosExistentes" sortMode="multiple">
            <ng-template pTemplate="header">
                <tr>
                    <th class='text-center col-m' pSortableColumn="name">
                        <p-sortIcon field="name"></p-sortIcon>Nombre
                    </th>
                    <th class='text-center col-s'>Acciones </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
                <tr>
                    <td class='text-center'>{{item.name}}</td>
            
                    <td class="text-center">
                        <p-button icon="pi pi-plus" class="p-button-secondary m-1" (click)="onUploadParamsExist(item)"
                        pTooltip="Seleccionar">
                    </p-button>
                        <p-button icon="pi pi-download" class="p-button-secondary m-1" (click)="download(item)"
                            pTooltip="Descargar">
                        </p-button>
                    </td>
                </tr>

            </ng-template>
        </p-table>
</p-dialog>
<div class="container min-width-600" *ngIf="!spinner">
    <div class="header">
        <h2>REPOSICIÓN<br /></h2>
    </div>
    <p-card class="card-container card-form min-width-500">
        <form [formGroup]="formDatos" class="ui large form">
            <div class="p-fluid grid formgrid">
                <h3>Carga de la planilla de entrada</h3>
                <div class="field col-12 md:col-12 ">
                    <label for="planilla">Planilla</label>
                    <i *ngIf="errorCombo" class="pi pi-check m-2"
                        style="font-weight: bold;font-size: 1rem;color: green;"></i>
                    <i *ngIf="errorCombo" class="pi pi-check m-2"
                        style="font-weight: bold;font-size: 1rem;color: red;"></i>
                    <div class="flex">
                        <input type="text" pInputText class="inputfield w-media" formControlName="fileName">
                        <p-fileUpload class="ml-2" name="demo[]" chooseLabel="Seleccionar" [auto]="true"
                            (onSelect)="onUpload($event)" maxFileSize="1000000">
                            <ng-template pTemplate="content">
                                <ul *ngIf="uploadedFiles">
                                    <li>{{uploadedFiles.name}} - {{uploadedFiles.size}} bytes</li>
                                </ul>
                            </ng-template>
                        </p-fileUpload>
                     
                    </div>

                </div>
                <div class="field col-12 md:col-12 ">
                    <p-divider></p-divider>
                </div>
                <div class="field col-12 md:col-12 ">
                    <label for="planilla">Parametros customizados</label>
                    <div class="flex">
                        <input type="text" pInputText class="inputfield w-media" formControlName="fileNameParametros">
                        <p-fileUpload class="ml-2" name="demo2[]" chooseLabel="Seleccionar" [auto]="true"
                            (onSelect)="onUploadParams($event)" maxFileSize="1000000">
                            <ng-template pTemplate="content">
                                <ul *ngIf="uploadedFilesParametros">
                                    <li>{{uploadedFilesParametros.name}} - {{uploadedFilesParametros.size}} bytes</li>
                                </ul>
                            </ng-template>
                        </p-fileUpload>
                        <p-button class="ml-2"  label="Planilla existente" icon="pi pi-plus "
                        (click)="planillaExistente()"></p-button>
                    </div>

                </div>
                <div class="field col-12 md:col-12 ">
                    <span style="font-size:14px">Nota: Si no ingresa ningún archivo customizado se tomarán los valores del archivo por defecto.</span>
                </div>
                <div class="field col-12 md:col-12 ">
                    <p-divider></p-divider>
                </div>
                <!--<div class="field col-12 md:col-12">
                    <label for="icon">Dias programados</label>
                    <i *ngIf="errorCombo" class="pi pi-check m-3"
                        style="font-weight: bold;font-size: 1rem;color: red;"></i>

                    <div class="flex ">
                        <p-inputNumber incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
                            buttonLayout="horizontal" formControlName="dias" [showButtons]="true"
                            inputId="minmax-buttons" [min]="0" [max]="100">
                        </p-inputNumber>
                    </div>
                </div>

                <div class="field col-12 md:col-12 ml-2">
                    <label for="icon">Hora / Minutos</label>
                    <i *ngIf="errorCombo" class="pi pi-check m-3"
                        style="font-weight: bold;font-size: 1rem;color: red;"></i>

                    <div class="flex ">
                        <p-inputNumber mode="decimal" [showButtons]="true" buttonLayout="vertical" [min]="0" [max]="23"
                            spinnerMode="vertical" decrementButtonClass="p-button-secondary"
                            incrementButtonClass="p-button-secondary" formControlName="horas"
                            incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"></p-inputNumber>
                        <span class="dos-puntos"> : </span>
                        <p-inputNumber mode="decimal" [showButtons]="true" buttonLayout="vertical" [min]="0" [max]="59"
                            spinnerMode="vertical" decrementButtonClass="p-button-secondary"
                            incrementButtonClass="p-button-secondary" formControlName="minutos"
                            incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"></p-inputNumber>
                    </div>
                </div>-->
                <div class="field col-12 md:col-12">
                    <label for="icon">Compartimientos por camión</label>
                    <i *ngIf="errorCombo" class="pi pi-check m-2"
                        style="font-weight: bold;font-size: 1rem;color: green;"></i>
                    <i *ngIf="errorCombo" class="pi pi-check m-2"
                        style="font-weight: bold;font-size: 1rem;color: red;"></i>
                    <div class="flex ">
                        <p-inputNumber incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
                            buttonLayout="horizontal" formControlName="compartimento" [showButtons]="true"
                            inputId="minmax-buttons" [min]="0" [max]="100">
                        </p-inputNumber>
                        <p-button class="ml-2" label="Agregar" icon="pi pi-plus
                    " (onClick)="addItem()"></p-button>
                    </div>
                    <div class="mt-4 " *ngIf="listCamiones.length> 0">
                        <h3>Compartimientos agregados</h3>
                        <div class="card-item" *ngFor="let item of listCamiones;index as i ">
                            <span class="card-label mr-2">Camión Nº : </span> {{ i+1 }} ,
                            <span class="card-label mr-2">Cantidad : </span> {{item}}
                            <i class="pi pi-times float-right pointer ml-4 mt-1" (click)="removeCamion(i)"
                                style="font-size: 1rem"></i>
                        </div>
                    </div>
                    <div class="mt-4" *ngIf="listCamiones.length == 0">
                        <div class="msg">
                            Aún no hay compartimientos agregados
                        </div>
                    </div>

                </div>
                <div *ngIf="errorCombo" class="msg">
                    <h5>Combinaciones obligatorias</h5>
                    <p> <i class="pi pi-check icon-warn" style="font-size: 1rem;color: green;"></i>
                        Primer combinacion</p>
                    <p> <i class="pi pi-check icon-warn" style="font-size: 1rem;color: red;"></i>
                        Segunda combinación</p>
                </div>


                <div class="field col-12 md:col-12 ">
                    <button pButton pRipple type="button" class="float-right btn" icon="pi pi-check " (click)="submit()"
                        label="Confirmar"></button>
                </div>
            </div>

        </form>
    </p-card>
</div>

<div class="container" *ngIf="spinner">
    <p-card class="text-center">
        <span>
            <p-progressSpinner></p-progressSpinner>
        </span>
        <br>
        <span class="text-center">Procesando...</span>
    </p-card>
</div>