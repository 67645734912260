<p-toast position="top-right" key="msg"></p-toast>
<p-dialog position="top" header="Carga de la planilla de parametros" [(visible)]="display">
    <form [formGroup]="formDatos" class="ui large form">
        <div class="p-fluid grid formgrid">
            <div class="field col-12 md:col-12 ">
                <label for="planilla">Planilla</label>
                <div class="flex">
                    <input type="text" pInputText class="inputfield w-media" formControlName="fileName">
                    <p-fileUpload class="ml-2" name="demo[]" chooseLabel="Seleccionar" [auto]="true"
                        (onSelect)="onUpload($event)" maxFileSize="1000000">
                        <ng-template pTemplate="content">
                            <ul *ngIf="uploadedFiles">
                                <li>{{uploadedFiles.name}} - {{uploadedFiles.size}} bytes</li>
                            </ul>
                        </ng-template>
                    </p-fileUpload>
                    <!--<p-button class="ml-2"  label="Seleccionar planilla existente" icon="pi pi-plus "
                        (click)="planillaExistente()"></p-button>-->
                </div>

            </div>
            <div class="field col-12 md:col-12 ">
                <label for="planilla">Descripción</label>
                <div class="flex">
                    <input type="text" pInputText class="inputfield w-media" formControlName="descripcion">
                </div>
            </div>
            <div class="field col-12 md:col-12 ">
                <div class="field-checkbox">
                    <p-checkbox formControlName="checked" [binary]="true" inputId="binary"></p-checkbox>
                    <label for="binary " style="margin-top: 0.5em;"> Asignar por defecto</label>
                </div>
            </div>

            <div class="field col-12 md:col-12 ">
                <p-divider></p-divider>
            </div>

            <div class="field col-12 md:col-12 ">
                <button pButton pRipple type="button" class="float-right btn" icon="pi pi-check "
                    (click)="guardarNuevo()" label="Confirmar"></button>
            </div>
        </div>

    </form>
</p-dialog>
<div class="container">

    <div class="header">
        <h2>PARAMETROS<br />
        </h2>

    </div>
    <p-card>

        <div class="text-center" *ngIf="archivoActual">
            <label>Archivo por defecto actual :</label>
            <span style="color:black">{{archivoActual.name}}</span>
           <!-- <p-button icon="pi pi-download" class="p-button-secondary m-1" (click)="download(archivoActual)"
                pTooltip="Descargar">
            </p-button>-->
        </div>
        <div class="text-center" *ngIf="!archivoActual">
            <label>No existe un archivo asignado</label>
           <!-- <p-button icon="pi pi-download" class="p-button-secondary m-1" (click)="download(archivoActual)"
                pTooltip="Descargar">
            </p-button>-->
        </div>
        <div class="text-center" *ngIf="isAdmin">

            <p-button (click)="showDialog()" label="Nuevo archivo" icon="pi pi-upload" class="p-button-secondary m-1 "
                pTooltip="Cargar nuevo archivo">
            </p-button>
        </div>
    </p-card>
    <p-card *ngIf="!spinner && results.length>0">

        <div class="scroll">
            <p-table [value]="list" sortMode="multiple">
                <ng-template pTemplate="header">
                    <tr>
                        <th class='text-center td-nombre' pSortableColumn="nombre">
                            <p-sortIcon field="name"></p-sortIcon>Nombre
                        </th>
                        <th class='text-center col-s' *ngIf="isAdmin">Por defecto </th>
                        <th class='text-center col-m'>Descripción </th>
                        <th class='text-center col-s'>Fecha </th>
                        <th class='text-center col-s'>Acciones </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item>
                    <tr>
                        <td class='text-center'>{{item.name}}</td>
                        <td class='text-center' *ngIf="isAdmin">
                            <p-checkbox name="group1" (click)="onChange(item)" [value]="item.active"
                                [(ngModel)]="item.active" [binary]="true" inputId="binary">
                            </p-checkbox>
                            <!--  <label *ngIf="item.active" for="binary " style="margin-top: 0.5em;"> Activo</label>
                            <label *ngIf="!item.active" style="margin-top: 0.5em;"> Inactivo</label>-->

                        </td>
                        <td class='text-center'>{{item.description}}</td>

                        <td class='text-center'>{{item.creationDate |
                            date:'mediumDate'}}</td>

                        <td class="text-center">
                            <!-- <p-button icon="pi pi-eye" class="p-button-secondary m-1" (click)="ir('/parametros/ver')"
                                pTooltip="Ver detalles">
                            </p-button>-->

                            <p-button icon="pi pi-download" class="p-button-secondary m-1" (click)="download(item)"
                                pTooltip="Descargar">
                            </p-button>
                        </td>
                    </tr>

                </ng-template>
            </p-table>
        </div>
    </p-card>
    <p-paginator  *ngIf="!spinner && results.length>0" [rows]="rows" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"
        (onPageChange)="paginate($event)"></p-paginator>
</div>

<div class="field col-lg-12 col-xl-12 " *ngIf="!spinner && results.length==0">
    <p-card>
        <div class="msg">
            Aún no hay resultados
        </div>
    </p-card>

</div>