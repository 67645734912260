import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-editar',
  templateUrl: './editar.component.html',
  styleUrls: ['./editar.component.scss']
})
export class EditarComponent implements OnInit {
  formDatos = this.form.group({
    producto: [''],
    presentacion: [''],
    formato: [''],

  });
  progressBarValue = 100;
  progressBarText = "Cargando datos...";
  planilla = "";
  uploadedFiles: any[] = [];
  constructor(private form: FormBuilder, private messageService: MessageService,) { }

  ngOnInit(): void {

  }

  onUpload(event) {
    let formData = new FormData();
    for (let file of event.files) {
      this.planilla = file.name;
      formData.append('planilla', file);
      this.uploadedFiles.push(file);

    }
    this.messageService.add({ key: 'msg', severity: 'success', summary: 'Hecho!', detail: 'El archivo se subio correctamente' });
  }


  submit() {
    this.progressBarText = "Cargando cambios...";
    this.progressBarValue = 0;
    let interval = setInterval(() => {
      this.progressBarValue = this.progressBarValue + Math.floor(Math.random() * 10) + 1;
      if (this.progressBarValue >= 100) {
        this.progressBarValue = 100;
        clearInterval(interval);
      }
    }, 100);
    this.messageService.add({ key: 'msg', severity: 'success', summary: 'Hecho!', detail: 'Los datos de actualizaron correctamente!!' });
  }
}
