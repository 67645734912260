import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiServerService } from 'src/app/core/services/api/api-server.service';
import { MessageService } from 'primeng/api';
import { IfStmt } from '@angular/compiler';

@Component({
  selector: 'app-resultados-list',
  templateUrl: './resultados-list.component.html',
  styleUrls: ['./resultados-list.component.scss']
})
export class ResultadosListComponent implements OnInit {
  error;
  list: any[] = []
  results: any[] = []
  totalRecords: number;
  rows = 10;
  page = 0;
  spinner = false;
  progressBarText = "Cargando datos...";
  palabra = "";
  title = "";
  resultUrl = "";
  first = 0;
  type = "";
  display: boolean = false;

  constructor(private router: Router, private service: ApiServerService, private messageService: MessageService) {
    var url = document.location.href;
    var index = url.lastIndexOf("/") + 1;
    var length = url.length;
    this.resultUrl = url.slice(index, length);

  }

  ngOnInit(): void {
    this.spinner = true;

    this.switch();
    setInterval(() => {
      this.switch();
    }, 10000);
  }

  paginate(event) {
    this.page = event.page;
    this.rows = event.rows;
    if (this.type == "HISTORY") {
      this.getAllHistory();
    } else {
      this.getAll();
    }
  }

  ir(url) {
    this.router.navigate([url]);
  }

  filtrar() {
    this.spinner = true;
    if (this.type == "HISTORY") {
      this.getAllHistory();
    } else {
      this.getAll();
    }
    this.spinner = false;
  }


  switch() {
    switch (this.resultUrl) {
      case "reposicion":
        this.title = "Reposición"
        this.type = "OPT";
        this.getAll();
        break;
      case "batchcamion":
        this.title = "Batch / Camión"
        this.type = "OPT_BATCH";
        this.getAll();
        break;
      case "historial":
        this.title = "Historial"
        this.type = "HISTORY";
        this.getAllHistory();

        break;
      default:
        // 
        break;
    }



  }


  download(item) {
    let salida = item.files.find(d => d.folder == "salida");
    if (salida) {
      let filename = salida.name
      let url = salida.url
      var element = document.createElement('a');
      element.setAttribute('href', url);
      element.setAttribute('download', filename);
      document.body.appendChild(element);
      element.click();
      this.messageService.add({ key: 'msg', severity: 'success', summary: 'Hecho!', detail: 'El archivo de resultado se descargo correctamente' });
    } else {
      this.messageService.add({ key: 'msg', severity: 'error', summary: 'Error', detail: 'Error al descargar el archivo de resultado' });
    }
  }


  downloadHistory1(item) {
    let salida = item.files.filter(d => d.folder == "salida");
    if (salida.length > 0) {
      /// file 1
      let filename = salida[0].name
      let url = salida[0].url
      var element = document.createElement('a');
      element.setAttribute('href', url);
      element.setAttribute('download', filename);
      document.body.appendChild(element);
      element.click();
      this.messageService.add({ key: 'msg', severity: 'success', summary: 'Hecho!', detail: 'El archivo de resultado se descargo correctamente' });
    } else {
      this.messageService.add({ key: 'msg', severity: 'error', summary: 'Error', detail: 'Error al descargar el archivo de resultado' });
    }
  }
  downloadHistory2(item) {
    let salida = item.files.filter(d => d.folder == "salida");
    if (salida.length > 0) {
      /// file 2
      let filename2 = salida[1].name
      let url2 = salida[1].url
      var element2 = document.createElement('a');
      element2.setAttribute('href', url2);
      element2.setAttribute('download', filename2);
      document.body.appendChild(element2);
      element2.click();
      this.messageService.add({ key: 'msg', severity: 'success', summary: 'Hecho!', detail: 'El archivo de resultado se descargo correctamente' });
    } else {
      this.messageService.add({ key: 'msg', severity: 'error', summary: 'Error', detail: 'Error al descargar el archivo de resultado' });
    }
  }
  getAllHistory() {
    this.service.getAllHistory(this.page, this.rows, this.palabra.trim()).subscribe(res => {
      this.list = []
      this.totalRecords = res.totalElements;
      this.rows = res.size;
      res.content.forEach(element => {
        var findIndex = this.list.findIndex(e => e.id == element.id);
        if (findIndex !== -1) {
          this.list[findIndex].statusCompleted = element.statusCompleted;
          this.list[findIndex].fechaInicio = element.fechaInicio;
          this.list[findIndex].statusMessage = element.statusMessage;
          this.list[findIndex].resultPath = element.resultPath;
          this.list[findIndex].porcentaje = this.esPorcentaje(element.statusMessage);

        } else {
          let entrada = element.files.find(d => d.folder == "entrada");
          element.nombre = (entrada) ? entrada.name : "no se encontro archivo de entrada";
          element.statusCompleted = element.statusCompleted;
          element.porcentaje = this.esPorcentaje(element.statusMessage);
          this.results.push(element);
          this.list.push(element);
        }

      });
      /* if (this.paginateData) {
         this.paginate(this.paginateData)
       } else {
         this.paginate({ page: 0, first: this.first, rows: this.rows });
       }*/
      this.spinner = false;
    })
  }
  getAll() {
    console.log(this.palabra.trim())
    this.service.getAll(this.page, this.type, this.rows, this.palabra.trim()).subscribe(res => {
      this.list = []
      this.totalRecords = res.totalElements;
      this.rows = res.size;
      res.content.forEach(element => {
        var findIndex = this.list.findIndex(e => e.id == element.id);
        if (findIndex !== -1) {
          this.list[findIndex].statusCompleted = element.statusCompleted;
          this.list[findIndex].fechaInicio = element.fechaInicio;
          this.list[findIndex].statusMessage = element.statusMessage;
          this.list[findIndex].resultPath = element.resultPath;
          this.list[findIndex].porcentaje = this.esPorcentaje(element.statusMessage);

        } else {
          let entrada = element.files.find(d => d.folder == "entrada");
          element.nombre = (entrada) ? entrada.name : "no se encontro archivo de entrada";
          element.statusCompleted = element.statusCompleted;
          element.porcentaje = this.esPorcentaje(element.statusMessage);
          this.results.push(element);
          this.list.push(element);
        }

      });
      /*if (this.paginateData) {
        this.paginate(this.paginateData)
      } else {
        this.paginate({ page: 0, first: this.first, rows: this.rows });
      }*/
      this.spinner = false;
    })
  }

  filtro(e) {
    return (this.palabra !== "") ? e.nombre != undefined && e.nombre.includes(this.palabra.trim()) : true;
  }


  esPorcentaje(status: any): any {
    if (!isNaN(status)) {
      return Number(status);
    } else {
      return 0;
    }
  }


  showDialog(error) {
    document.getElementById("sidebar").style.zIndex = "97"
    this.error = error;
    this.display = true;
  }
  OnDestroy() {
    document.getElementById("sidebar").style.zIndex = "99"
  }
}
